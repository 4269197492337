import React from "react";

export const DummyTimeline = () => {
  return (
    <div className="flex flex-col md:flex-row h-full md:w-full gap-2 px-4 md:my-8 cursor-default select-none text-sm">
      <div className="flex flex-row-reverse justify-end md:justify-normal md:flex-col items-center h-full md:h-full md:w-full gap-2">
        <h4 className="md:text-center font-normal w-3/5 text-left md:w-full">
          Eg. Indian Institute of Technology (IIT)
        </h4>
        <div>
          <h4 className="h-10 w-10 rounded-full bg-entntorange/70 flex items-center justify-center text-white font-semibold text-sm">
            46
          </h4>
        </div>
        <div className="h-full w-3 md:h-3 md:w-full rounded-lg bg-entntorange/70"></div>
      </div>
      <div className="flex flex-row-reverse justify-end md:justify-normal md:flex-col items-center h-3/5 md:h-full md:w-3/5 gap-2">
        <h4 className="text-center font-normal">Eg. ENTNT</h4>
        <div>
          <h4 className="h-10 w-10 rounded-full bg-entntblue/70 flex items-center justify-center text-white font-semibold">
            18
          </h4>
        </div>
        <div className="h-full w-3 md:h-3 md:w-full rounded-lg bg-entntblue/70 text-sm"></div>
      </div>
    </div>
  );
};
