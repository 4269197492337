import React, { useRef, useState } from "react";
import {
  ArrowUpTrayIcon,
  PencilSquareIcon,
  CheckIcon,
  CalendarDaysIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/20/solid";

import { City, InputFieldType } from "../../../Models";
import { InputField } from "../../shared/InputField";
import useClickOutside from "../../../hooks/useClickOutside";
import ReactDatePicker from "react-datepicker";
import { addYears, format } from "date-fns";
import useCareerTimelineState from "../../../hooks/useCareerTimelineState";
import useCityandState from "../../../hooks/useCityandState";
import { isValidEmail, isValidLinkedinUrl } from "../../../Helpers";
import { ResumeUpload } from "./ResumeUpload";
import WorkLocation from "./WorkLocation";
import { emailExists } from "../../../WebCalls";
import ConfirmModal from "../../shared/ConfirmModal";
import { useNavigate, useParams } from "react-router-dom";
import useCandidateProfile from "../../../hooks/useCandidateProfile";

type props = {
  careerTimelineStates: ReturnType<typeof useCareerTimelineState>;
  cityandState: ReturnType<typeof useCityandState>;
  openError: boolean;
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
  timeline: any[];
};

export const CareerTimelineHeader = ({
  careerTimelineStates,
  cityandState,
  openError,
  setOpenError,
  timeline,
}: props) => {
  const [tempFirstName, setTempFirstName] = useState("");
  const [tempLastName, setTempLastName] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [tempLinkedinProfile, setTempLinkedinProfile] = useState("");
  const [showFullName, setShowFullName] = useState(false);
  const [showDOB, setShowDOB] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showCV, setShowCV] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showLinkedin, setShowLinkedin] = useState(false);
  const navigate = useNavigate();
  const fullNameRef = useRef(null);
  const dobRef = useRef(null);
  const locationRef = useRef(null);
  const cvRef = useRef(null);
  const emailRef = useRef(null);
  const linkedinRef = useRef(null);
  const [showSubcityConfirmation, setShowSubcityConfirmation] = useState(false);
  const [existingLocation, setExistingLocation] = useState(false);
  const isSmallScreen = window.innerWidth < 1024;
  const [emailExist, setEmailExist] = useState(false);
  const [errors, setErrors] = useState({
    dob: { touched: false, error: "Date of Birth is required." },
    workLocation: {
      touched: false,
      error: "At least one work location is required.",
    },
  });
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dob,
    setDOB,
    email,
    setEmail,
    cv,
    setCV,
    workLocations,
    setWorkLocations,
    linkedinProfile,
    setLinkedinProfile,
    cvFileName,
    setCvFileName,
    initialEmail,
  } = careerTimelineStates;

  const { cities, states } = cityandState;
  const candidate = useCandidateProfile();

  const personalInfoFields = [
    {
      id: 1,
      label: "First name",
      inputId: "firstName",
      autoComplete: "given-name",
      placeHolder: "First Name",
      value: isSmallScreen ? firstName : tempFirstName,
      setValue: isSmallScreen ? setFirstName : setTempFirstName,
      mandatoryField: true,
      error: "First name is required",
      validate: (v: string) =>
        v.trim() === "" ? "First name is required." : "",
    },
    {
      id: 2,
      label: "Last name",
      inputId: "lastName",
      autoComplete: "family-name",
      placeHolder: "Last Name",
      value: isSmallScreen ? lastName : tempLastName,
      setValue: isSmallScreen ? setLastName : setTempLastName,
      mandatoryField: true,
      error: "Last name is required",
      validate: (v: string) =>
        v.trim() === "" ? "Last name is required." : "",
    },
    {
      id: 3,
      isDateOfBirth: true,
    },
    {
      id: 4,
      label: "Email address",
      inputId: "email",
      autoComplete: "email",
      placeHolder: "eg. example@email.com",
      value: email,
      setValue: setEmail,
      mandatoryField: true,
      error: "Email is required",
      validate: (v: string) =>
        v.trim() === "" && isValidEmail(v) ? "Email is required." : "",
    },
    {
      id: 5,
      label: "Linkedin Profile URL",
      inputId: "linkedin-profile",
      placeHolder: "eg. www.linkedin.com/in/username/",
      value: linkedinProfile,
      setValue: setLinkedinProfile,
      validate: (v: string) =>
        v.trim() === "" && !isValidLinkedinUrl(v)
          ? "Please enter a valid LinkedIn URL"
          : "",
    },
  ];

  useClickOutside(fullNameRef, () => setShowFullName(false));
  useClickOutside(dobRef, () => setShowDOB(false));
  useClickOutside(locationRef, () => setShowLocation(false));
  useClickOutside(cvRef, () => setShowCV(false));
  useClickOutside(emailRef, () => setShowEmail(false));
  useClickOutside(linkedinRef, () => setShowLinkedin(false));

  const clearState = () => {
    if (
      firstName.trim().length === 0 &&
      lastName.trim().length === 0 &&
      email.trim().length === 0 &&
      linkedinProfile.trim().length === 0
    ) {
      setTempFirstName("");
      setTempLastName("");
      setTempEmail("");
      setTempLinkedinProfile("");
    } else {
      setTempFirstName(firstName);
      setTempLastName(lastName);
      setTempEmail(email);
      setTempLinkedinProfile(linkedinProfile);
    }
  };

  const handleFullName = () => {
    if (tempFirstName.trim().length !== 0 || tempLastName.trim().length !== 0) {
      setFirstName(tempFirstName);
      setLastName(tempLastName);
      setShowFullName(false);
    }
  };

  const handleEmail = async () => {
    if (initialEmail && tempEmail === initialEmail) {
      setEmail(tempEmail);
      setShowEmail(false);
      return;
    }
    if (tempEmail.trim().length !== 0 && isValidEmail(tempEmail)) {
      const emailExist = await emailExists(tempEmail);
      if (emailExist !== null && emailExist) {
        setEmailExist(true);
      } else setEmail(tempEmail);
      setShowEmail(false);
    }
  };

  const handleLinkedInURL = () => {
    if (
      tempLinkedinProfile.trim().length !== 0 &&
      isValidLinkedinUrl(tempLinkedinProfile)
    ) {
      setLinkedinProfile(tempLinkedinProfile);
      setShowLinkedin(false);
    }
  };

  const handleDeleteLocation = (locationId: string) => {
    const updatedWorkLocation = workLocations.filter(
      (loc: City) => loc.id !== locationId,
    );

    setWorkLocations(updatedWorkLocation);
  };

  const handleCVDownload = () => {
    const currentCV = "data:application/pdf;base64," + cv;
    const link = document.createElement("a");
    link.href = currentCV;
    link.download = `${firstName}_${lastName}_CV.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <div className="hidden w-full relative text-xs lg:flex justify-between">
        <div className="" ref={!openError ? emailRef : null}>
          <div className="flex justify-end">
            <h1
              className="cursor-pointer h-fit w-fit font-semibold flex items-center gap-1"
              onClick={() => {
                setShowEmail(!showEmail);
                clearState();
              }}
            >
              {email.trim().length !== 0 ? email : "Email"}
              <PencilSquareIcon height={14} className="text-entntblue" />
            </h1>
          </div>
          {showEmail && (
            <div className="absolute mt-2 p-2 pb-4 z-10 flex items-start gap-4 rounded-md bg-gray-100 shadow-md">
              <div className="flex gap-4">
                <InputField
                  label="Email address"
                  inputId="email"
                  placeHolder="eg. example@email.com"
                  value={tempEmail}
                  setValue={setTempEmail}
                  mandatoryField
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleEmail();
                    }
                  }}
                  error="Email is required"
                  validate={(v: string) =>
                    v.trim() !== "" && !isValidEmail(v)
                      ? "Please enter a valid Email."
                      : ""
                  }
                />
                <div className="flex items-end">
                  <CheckIcon
                    height={32}
                    className={`bg-entntblue text-white rounded-md ${
                      !isValidEmail(tempEmail)
                        ? "cursor-default bg-gray-300"
                        : "hover:bg-entntorange cursor-pointer "
                    }`}
                    onClick={handleEmail}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div ref={!openError ? fullNameRef : null}>
          <h1
            className="cursor-pointer w-fit h-fit font-semibold flex items-center gap-1"
            onClick={() => {
              setShowFullName(!showFullName);
              clearState();
            }}
          >
            {firstName.trim().length !== 0 && lastName.trim().length !== 0
              ? `${firstName} ${lastName}`
              : "Full Name"}
            <PencilSquareIcon height={14} className="text-entntblue" />
          </h1>
          {showFullName && (
            <div className="absolute mt-2 p-2 pb-4 z-10 flex gap-4 rounded-md bg-gray-100 shadow-md">
              {personalInfoFields?.slice(0, 2).map((field: InputFieldType) => (
                <div key={field.id}>
                  <InputField
                    label={field.label}
                    inputId={field.inputId}
                    autoComplete={field.autoComplete}
                    placeHolder={field.placeHolder}
                    value={field.value}
                    setValue={field.setValue}
                    mandatoryField
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleFullName();
                      }
                    }}
                    error={field.error}
                    validate={field.validate}
                  />
                </div>
              ))}
              <div className="flex items-end">
                <CheckIcon
                  height={32}
                  className={`bg-entntblue text-white rounded-md ${
                    tempFirstName.trim() === "" || tempLastName.trim() === ""
                      ? "cursor-default bg-gray-300"
                      : "hover:bg-entntorange cursor-pointer "
                  }`}
                  onClick={handleFullName}
                />
              </div>
            </div>
          )}
        </div>
        <div ref={!openError ? dobRef : null}>
          <h1
            className="cursor-pointer w-fit h-fit font-semibold flex items-center gap-1"
            onClick={() => {
              setShowDOB(!showDOB);
              clearState();
            }}
          >
            {dob ? `${format(dob, "MMM dd yyyy")}` : "DOB"}
            <PencilSquareIcon height={14} className="text-entntblue" />
          </h1>
          {showDOB && (
            <div className="absolute w-52 mt-2 p-2 z-10 rounded-md bg-gray-100 shadow-md">
              <div className="text-sm leading-6 font-medium text-gray-900">
                <label htmlFor="dob">
                  Date of Birth
                  <span className="text-red-600 pl-1">*</span>
                </label>
                <ReactDatePicker
                  id="dob"
                  className="block mt-2 w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm sm:leading-5"
                  selected={dob}
                  onChange={(date) => {
                    date && setDOB(date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  maxDate={
                    timeline.length > 0
                      ? addYears(timeline[0].startDate, -17)
                      : new Date("2012-12-31")
                  }
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  placeholderText="eg. 01/01/2000"
                  closeOnScroll
                  showIcon
                  calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                  icon={
                    <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                  }
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      dob && setShowDOB(false);
                    }
                  }}
                  onSelect={() => dob && setShowDOB(false)}
                />
              </div>
            </div>
          )}
        </div>
        <div
          ref={
            !showSubcityConfirmation && !existingLocation ? locationRef : null
          }
        >
          <h1
            className="cursor-pointer h-fit w-fit font-semibold mx-auto flex items-center gap-1"
            onClick={() => {
              setShowLocation(!showLocation);
            }}
          >
            {workLocations.length !== 0
              ? workLocations[0]?.name.trim().length !== 0
                ? workLocations[0]?.name
                : workLocations[0]?.nearestCity
              : "Add Location(s)"}
            {workLocations[1] && (
              <span>
                ,
                {workLocations[1]?.name.trim().length !== 0
                  ? workLocations[1]?.name
                  : workLocations[1]?.nearestCity}
              </span>
            )}
            <PencilSquareIcon height={14} className="text-entntblue" />
          </h1>
          {showLocation && (
            <div className="absolute z-10 mt-2 p-2 rounded-md bg-gray-100 shadow-md">
              <WorkLocation
                states={states}
                cities={cities}
                workLocations={workLocations}
                setWorkLocations={setWorkLocations}
                showSubcityConfirmation={showSubcityConfirmation}
                setShowSubcityConfirmation={setShowSubcityConfirmation}
                existingLocation={existingLocation}
                setExistingLocation={setExistingLocation}
                handleDeleteLocation={handleDeleteLocation}
              />
            </div>
          )}
        </div>
        <div ref={cvRef}>
          <h1
            className="cursor-pointer h-fit w-fit font-semibold mx-auto flex items-center gap-1"
            onClick={() => {
              setShowCV(!showCV);
            }}
          >
            {cv.trim().length !== 0 ? "Edit CV" : "Upload CV"}
            {cv.trim().length !== 0 ? (
              <PencilSquareIcon height={14} className="text-entntblue" />
            ) : (
              <ArrowUpTrayIcon height={14} className="text-entntblue" />
            )}
          </h1>
          {showCV && (
            <div className="absolute mt-2 p-2 z-10 flex rounded-md bg-gray-100 shadow-md">
              <ResumeUpload
                cvFileName={cvFileName}
                setCvFileName={setCvFileName}
                cv={cv}
                setCV={setCV}
              />
              {candidate && (
                <ArrowDownTrayIcon
                  color="blue"
                  height={20}
                  cursor="pointer"
                  onClick={handleCVDownload}
                />
              )}
            </div>
          )}
        </div>

        <div className="" ref={!openError ? linkedinRef : null}>
          <div className="flex justify-end">
            <h1
              className="cursor-pointer h-fit w-fit font-semibold flex items-center gap-1"
              onClick={() => {
                setShowLinkedin(!showLinkedin);
                clearState();
              }}
            >
              {linkedinProfile.trim().length !== 0
                ? linkedinProfile
                : "Linkedin Profile"}
              <PencilSquareIcon height={14} className="text-entntblue" />
            </h1>
          </div>
          {showLinkedin && (
            <div className="absolute right-0 mt-2 p-2 pb-4 z-10 flex items-start gap-4 rounded-md bg-gray-100 shadow-md">
              <div className="flex gap-4">
                <InputField
                  label="Linkedin Profile URL"
                  inputId="linkedin-profile"
                  placeHolder="eg. www.linkedin.com/in/username/"
                  value={tempLinkedinProfile}
                  setValue={setTempLinkedinProfile}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleLinkedInURL();
                    }
                  }}
                  validate={(v: string) =>
                    v.trim() !== "" && !isValidLinkedinUrl(v)
                      ? "Please enter a valid LinkedIn URL."
                      : ""
                  }
                />
                <div className="flex items-end">
                  <CheckIcon
                    height={32}
                    className={`bg-entntblue text-white rounded-md ${
                      !isValidLinkedinUrl(tempLinkedinProfile)
                        ? "cursor-default bg-gray-300"
                        : "hover:bg-entntorange cursor-pointer "
                    }`}
                    onClick={handleLinkedInURL}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mobile screen */}
      <div className="lg:hidden grid grid-cols-2 gap-4">
        {personalInfoFields.map((field) =>
          field.isDateOfBirth ? (
            <div
              key={field.id}
              className="col-span-full sm:col-span-1 font-medium text-sm leading-6 text-gray-900 z-10"
            >
              <label htmlFor="dob">
                Date of Birth
                <span className="text-red-600 pl-1">*</span>
              </label>
              <ReactDatePicker
                id="dob"
                className={`block mt-2 w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm font-medium leading-5 ${
                  !dob &&
                  errors?.dob.touched &&
                  errors?.dob.error &&
                  "ring-red-500"
                }`}
                selected={dob}
                onChange={(date) => {
                  date && setDOB(date);
                  setErrors((prev) => ({
                    ...prev,
                    dob: {
                      ...prev.dob,
                      error: !date ? "Date of Birth is required" : "",
                    },
                  }));
                }}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date("2012-12-31")}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                placeholderText="eg. 01/01/2000"
                closeOnScroll
                showIcon
                calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                icon={
                  <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                onFocus={(e) => {
                  if (/iPhone|iPad/i.test(navigator.userAgent)) {
                    e.target.readOnly = true;
                  }
                }}
                onBlur={() =>
                  setErrors((prev) => ({
                    ...prev,
                    dob: {
                      ...prev.dob,
                      touched: true,
                    },
                  }))
                }
              />
              {!dob && errors.dob.touched && errors.dob.error && (
                <p className="absolute text-xs text-red-500">
                  {errors.dob.error}
                </p>
              )}
            </div>
          ) : (
            <div
              key={field.id}
              className={
                field.id <= 4 ? "col-span-full sm:col-span-1" : "col-span-full"
              }
            >
              <InputField
                label={field.label}
                inputId={field.inputId}
                autoComplete={field.autoComplete}
                placeHolder={field.placeHolder}
                value={field.value}
                setValue={field.setValue}
                mandatoryField={field.mandatoryField}
                error={field.error}
                validate={field.validate}
              />
            </div>
          ),
        )}
        <div className="col-span-full">
          <WorkLocation
            states={states}
            cities={cities}
            workLocations={workLocations}
            setWorkLocations={setWorkLocations}
            showSubcityConfirmation={showSubcityConfirmation}
            setShowSubcityConfirmation={setShowSubcityConfirmation}
            existingLocation={existingLocation}
            setExistingLocation={setExistingLocation}
            handleDeleteLocation={handleDeleteLocation}
            onBlur={() =>
              setErrors((prev) => ({
                ...prev,
                workLocation: {
                  ...prev.workLocation,
                  touched: true,
                },
              }))
            }
          />
          {errors.workLocation.touched &&
            errors.workLocation.error &&
            workLocations.length === 0 && (
              <p className="absolute text-xs text-red-500">
                {errors.workLocation?.error}
              </p>
            )}
        </div>
        <div className="col-span-full">
          <div className="flex items-center space-x-4">
            <ResumeUpload
              cvFileName={cvFileName}
              setCvFileName={setCvFileName}
              cv={cv}
              setCV={setCV}
            />
            {candidate && (
              <ArrowDownTrayIcon
                color="blue"
                height={20}
                cursor="pointer"
                className="mt-7"
                onClick={() => handleCVDownload()}
              />
            )}
          </div>
        </div>
        {
          <ConfirmModal
            type={"info"}
            open={emailExist}
            setOpen={setEmailExist}
            title={"Profile already exists"}
            description={
              "The profile with this email address already exists. Please navigate to the login page to access your account."
            }
            buttonContents={[
              {
                buttonId: 1,
                buttonLabel: "Login Page",
                buttonAction: () => {
                  navigate("/candidate/login");
                },
              },
              {
                buttonId: 2,
                buttonLabel: "Cancel",
                buttonAction: () => {
                  setTempEmail("");
                  setEmailExist(false);
                },
                isOutline: true,
              },
            ]}
          />
        }
      </div>
    </>
  );
};
