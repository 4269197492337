import { Tooltip } from "react-tooltip";

interface ReactTooltipProps {
  id: string;
  content: string;
}

const ReactTooltip = ({ id, content }: ReactTooltipProps) => (
  <Tooltip
    id={id}
    place="bottom"
    variant="dark"
    content={content}
    className="!max-w-xs z-10"
  />
);

export default ReactTooltip;
