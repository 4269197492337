import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  AcademicCapIcon,
  XCircleIcon,
  BriefcaseIcon,
} from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import useCityandState from "../../hooks/useCityandState";
import SearchDropDown from "./SearchDropDown";
import { InputField } from "./InputField";
import { isValidUrl } from "../../Helpers";
import { City, University, dropDownValueType } from "../../Models";
import { GetCompanies, GetUniversities } from "../../WebCalls";
import useFetchInstitutions from "../../hooks/useFetchInstitutions";
import ToggleSwitch from "./ToggleSwitch";
import ReactTooltip from "./ReactTooltip";

type InstitutionsFieldsType = {
  institutionName: dropDownValueType | undefined;
  setInstitutionName: React.Dispatch<
    React.SetStateAction<dropDownValueType | undefined>
  >;
  newInstitutionName: string;
  setNewInstitutionName: React.Dispatch<React.SetStateAction<string>>;
  newInstitutionUrl: string;
  setNewInstitutionUrl: React.Dispatch<React.SetStateAction<string>>;
  institutionCity: City | undefined;
  setInstitutionCity: React.Dispatch<React.SetStateAction<City | undefined>>;
  instClosestCity: City | undefined;
  setInstClosestCity: React.Dispatch<React.SetStateAction<City | undefined>>;
  instSubCity: string;
  setInstSubCity: React.Dispatch<React.SetStateAction<string>>;
};

type NoUrlFieldsType = {
  noUrl: boolean;
  setnoUrl: React.Dispatch<React.SetStateAction<boolean>>;
  isSelfEmployed: boolean;
  setisSelfEmployed: React.Dispatch<React.SetStateAction<boolean>>;
  notMentioned: boolean;
  setnotMentioned: React.Dispatch<React.SetStateAction<boolean>>;
};

type props = {
  type: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  institutionType?: string;
  cityandState: ReturnType<typeof useCityandState>;
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
  inEditMode?: boolean;
  institutionsFields: InstitutionsFieldsType;
  noUrlFields?: NoUrlFieldsType;
};

export default function NewInstitutionEntryModal(props: props) {
  const cancelButtonRef = useRef(null);
  const danger = props.type === "danger";
  const [matchedNames, setMatchedNames] = useState<University[]>([]);
  const [tempCities, setTempCities] = useState<dropDownValueType[]>([]);
  const { cities, state, setState, states } = props.cityandState;
  const {
    institutionName,
    setInstitutionName,
    newInstitutionName,
    setNewInstitutionName,
    newInstitutionUrl,
    setNewInstitutionUrl,
    institutionCity,
    setInstitutionCity,
    instClosestCity,
    setInstClosestCity,
    instSubCity,
    setInstSubCity,
  } = props.institutionsFields;

  const {
    noUrl = props.noUrlFields?.noUrl,
    setnoUrl = () => {},
    isSelfEmployed = props.noUrlFields?.isSelfEmployed,
    setisSelfEmployed = () => {},
    notMentioned = props.noUrlFields?.notMentioned,
    setnotMentioned = () => {},
  } = props.noUrlFields || {};

  const isExistingUni = matchedNames.some((u) => u.id === institutionName?.id);

  const { data: matchedInstitutions, setUrlSearch } = useFetchInstitutions(
    props.institutionType === "company" ? GetCompanies : GetUniversities,
    "",
    1,
    1000,
  );

  useEffect(() => {
    setTempCities(cities.filter((city) => city.state?.id === state?.id));
    if (
      institutionCity?.state?.name !== state?.name &&
      institutionCity?.name.toLocaleLowerCase() !== "other"
    ) {
      setInstitutionCity({ id: "", name: "" });
    }
    if (instClosestCity?.state?.name !== state?.name) {
      setInstClosestCity({ id: "", name: "" });
    }
  }, [state]);

  useEffect(() => {
    const fetchAndSetMatchedNames = () => {
      setUrlSearch(newInstitutionUrl);
      if (newInstitutionUrl && matchedInstitutions.length !== 0) {
        const uniqueNames = matchedInstitutions.filter(
          (uni, index, self) =>
            index === self.findIndex((u) => u.name === uni.name),
        );
        setMatchedNames(uniqueNames);
      } else {
        setMatchedNames([]);
      }
    };
    fetchAndSetMatchedNames();
  }, [newInstitutionUrl, matchedInstitutions]);

  const handleUniversityUrlClose = () => {
    if (newInstitutionUrl.trim().length !== 0) {
      setNewInstitutionUrl("");
      setMatchedNames([]);
      setNewInstitutionName("");
    }
  };

  const handleSubcityClose = () => {
    if (instSubCity.trim().length !== 0) {
      setNewInstitutionName("");
      setNewInstitutionUrl("");
      setInstSubCity("");
    } else {
      setInstitutionCity({ id: "", name: "" });
      setInstClosestCity({ id: "", name: "" });
      setInstitutionName({ id: "", name: "" });
    }
  };

  const handleMatchedNames = (uni: University) => {
    if (uni) {
      setNewInstitutionName(uni.name);
      return;
    }
  };

  const validateOnSave = () => {
    const isOtherCity = institutionCity?.name.toLowerCase() === "other";

    const isCityValid = isOtherCity
      ? instSubCity.trim().length > 0
      : institutionCity?.name;

    const isNewInstValid =
      newInstitutionName && newInstitutionUrl && isValidUrl(newInstitutionUrl);

    const isNoUrlValid = noUrl && (isSelfEmployed || notMentioned);

    return (
      !state ||
      !isCityValid ||
      (props.institutionType === "company" && noUrl
        ? !isNoUrlValid
        : !isNewInstValid)
    );
  };

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();
    // const isOtherCity = institutionCity?.name.toLowerCase() === "other";

    // const isCityValid = isOtherCity
    //   ? instSubCity.trim().length > 0
    //   : institutionCity?.name;

    // const isNewInstValid =
    //   newInstitutionName && newInstitutionUrl && isValidUrl(newInstitutionUrl);

    // const isNoUrlValid = noUrl && (isSelfEmployed || notMentioned);

    if (validateOnSave()) {
      // props.setOpenError(true);
      return;
    } else {
      props.setOpen(false);
      if (isExistingUni) {
        setNewInstitutionName("");
        setNewInstitutionUrl("");
        setInstSubCity("");
        setInstitutionCity({ id: "", name: "" });
        setState({ id: "", name: "" });
      } else setInstitutionName({ id: "1", name: "Other" });
    }
  };

  const handleCancel = () => {
    if (!props.inEditMode) {
      setNewInstitutionName("");
      setNewInstitutionUrl("");
      setInstSubCity("");
      setInstitutionCity({ id: "", name: "" });
      setState({ id: "", name: "" });
      setnoUrl(false);
      setnotMentioned(false);
      setisSelfEmployed(false);
    }
    props.setOpen(false);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        id="institution-enrty-modal"
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          document.getElementById("subcity-modal")?.id !== "subcity-modal" &&
            document.getElementById("error-modal")?.id !== "error-modal" &&
            handleCancel();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-2xl ">
                <div className="bg-white px-4 pt-4 py-2 border-b shadow-sm">
                  <div className="sm:flex sm:items-center">
                    <div
                      className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                        danger ? "bg-red-100" : "bg-blue-100"
                      } sm:mx-0 `}
                    >
                      {props.institutionType === "company" ? (
                        <BriefcaseIcon
                          className={`h-6 w-6 ${
                            danger ? "text-red-600" : "text-blue-600"
                          }`}
                          aria-hidden="true"
                        />
                      ) : (
                        <AcademicCapIcon
                          className={`h-6 w-6 ${
                            danger ? "text-red-600" : "text-blue-600"
                          }`}
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="text-center sm:ml-3 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 text-center"
                      >
                        {props.title}
                      </Dialog.Title>
                    </div>
                  </div>
                </div>
                <div className="px-10 pt-2 pb-4">
                  <div className="space-y-3 lg:space-y-0 lg:grid lg:grid-cols-6 gap-3">
                    {/* Dropdown States */}
                    <div className="flex items-end w-full col-span-3">
                      <SearchDropDown
                        optionsClassName={`${noUrl && "!max-h-20"}`}
                        label="State"
                        placeHolder="Select State"
                        dropDownValues={states}
                        state={state}
                        setState={(value) => {
                          setState(value);
                          setInstitutionCity({ id: "", name: "" });
                          setInstSubCity("");
                        }}
                        mandatoryField
                        hideOthers
                        error="Please select a state."
                      />
                    </div>

                    {/* Dropdown Cities */}
                    {institutionCity?.name.toLowerCase() !== "other" && (
                      <div className="flex items-end w-full col-span-3">
                        <SearchDropDown
                          label="City"
                          placeHolder="Select City"
                          dropDownValues={tempCities}
                          state={institutionCity}
                          setState={setInstitutionCity}
                          disable={state?.name.trim().length === 0 || !state}
                          mandatoryField
                          error="Please select a city."
                        />
                      </div>
                    )}

                    {/* New City fields */}
                    {institutionCity?.name.toLowerCase() === "other" && (
                      <div className="relative sm:flex items-end gap-6 col-span-3">
                        {/* <SearchDropDown
                          label="Nearest City"
                          placeHolder="Select City"
                          dropDownValues={tempCities}
                          state={instClosestCity}
                          setState={(city) => {
                            setInstSubCity("");
                            setInstClosestCity(city);
                          }}
                          mandatoryField
                          hideOthers
                          disable={state?.name.trim().length === 0}
                        /> */}
                        <InputField
                          label="City Name"
                          value={instSubCity}
                          setValue={setInstSubCity}
                          placeHolder="eg. Chennai"
                          mandatoryField
                          error="Please enter a city name."
                          validate={(v: string) =>
                            v.trim() === "" ? "Please enter a city name." : ""
                          }
                        />
                        <XCircleIcon
                          onClick={handleSubcityClose}
                          className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
                        />
                      </div>
                    )}

                    {/* New University name & URL fields */}
                    {!noUrl && (
                      <>
                        <div className="relative col-span-full flex items-end justify-end w-full">
                          <InputField
                            label={
                              props.institutionType === "company"
                                ? "Company URL"
                                : "University URL"
                            }
                            value={newInstitutionUrl}
                            setValue={setNewInstitutionUrl}
                            placeHolder={
                              props.institutionType === "company"
                                ? "eg. www.companyname.com"
                                : "eg. www.universityname.com"
                            }
                            mandatoryField
                            error="URL is required."
                            isDisabled={
                              !state?.name ||
                              institutionCity?.name.toLowerCase() === "other"
                                ? !instSubCity
                                : !institutionCity?.name
                            }
                            validate={(v: string) =>
                              v.trim() !== "" && !isValidUrl(v)
                                ? "Please enter a valid URL."
                                : ""
                            }
                          />
                          <XCircleIcon
                            onClick={handleUniversityUrlClose}
                            className="w-6 h-6 bg-white rounded-full absolute bottom-1 mr-1 cursor-pointer  fill-red-600"
                          />
                        </div>
                        <div
                          className={`relative col-span-full pb-2 ${
                            matchedNames.length !== 0 && "pb-4"
                          }`}
                        >
                          <InputField
                            label={
                              props.institutionType === "company"
                                ? "Company Name"
                                : "University Name"
                            }
                            value={newInstitutionName}
                            setValue={setNewInstitutionName}
                            placeHolder={
                              props.institutionType === "company"
                                ? "Enter your Company Name"
                                : "Enter your University Name"
                            }
                            mandatoryField
                            isDisabled={newInstitutionUrl === ""}
                            error={`Please enter a ${
                              props.institutionType === "company"
                                ? "company"
                                : "university"
                            } name.`}
                            validate={(v: string) =>
                              v.trim() === ""
                                ? `Please enter a ${
                                    props.institutionType === "company"
                                      ? "company"
                                      : "university"
                                  } name.`
                                : ""
                            }
                          />
                          {matchedNames.length > 0 && (
                            <div className="absolute w-full text-xs flex items-center gap-2">
                              <div className="text-blue-700 whitespace-nowrap pt-1">
                                Suggested Names :
                              </div>
                              <div className="flex gap-2 flex-wrap pt-1 w-full text-xs text-blue-700">
                                {matchedNames.map((uni, index) => (
                                  <div
                                    key={index}
                                    className="cursor-pointer"
                                    onClick={() => handleMatchedNames(uni)}
                                  >
                                    {uni.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {props.institutionType === "company" && (
                      <>
                        <div className="col-span-2 flex gap-2">
                          <label
                            htmlFor="nourl"
                            className="flex whitespace-nowrap text-sm font-medium text-gray-900"
                          >
                            No URL
                            <InformationCircleIcon
                              data-tooltip-id="nourl-tooltip"
                              className="w-6 h-6 ml-2"
                            />
                          </label>
                          <ToggleSwitch
                            id="nourl"
                            checked={noUrl}
                            setState={() => {
                              setnoUrl(!noUrl);
                              setNewInstitutionUrl("");
                              setNewInstitutionName("");
                              setnotMentioned(false);
                              setisSelfEmployed(false);
                            }}
                          />
                        </div>
                        {noUrl && (
                          <>
                            <div className="col-span-2 flex items-end justify-center">
                              <label
                                htmlFor="selfEmployed"
                                className="flex gap-2 text-sm font-medium leading-6 text-gray-900"
                              >
                                Self Employed
                                <ToggleSwitch
                                  id="selfEmployed"
                                  checked={isSelfEmployed}
                                  setState={() => {
                                    setisSelfEmployed(true);
                                    setNewInstitutionUrl("");
                                    setnotMentioned(false);
                                    setnotMentioned(false);
                                  }}
                                />
                              </label>
                            </div>
                            <div className="col-span-1 flex items-end justify-end">
                              <label
                                htmlFor="notMentioned"
                                className="flex gap-2 text-sm font-medium leading-6 text-gray-900"
                              >
                                Other
                                <ToggleSwitch
                                  id="notMentioned"
                                  checked={notMentioned}
                                  setState={() => {
                                    setisSelfEmployed(false);
                                    setnotMentioned(true);
                                  }}
                                />
                              </label>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mx-4 bg-red-500 text-white "
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  mx-4 text-white 
                      ${
                        validateOnSave()
                          ? "bg-gray-300"
                          : "bg-entntblue hover:bg-entntorange"
                      }
                      `}
                    onClick={(e) => handleSave(e)}
                    disabled={validateOnSave()}
                  >
                    Save
                  </button>
                </div>
                <ReactTooltip
                  id="nourl-tooltip"
                  content="If there is no company URL, you can click and choose the 'Self Employed' toggle if you are self-employed, or the 'Other' toggle if the experience is different."
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
