import { useState, useCallback, useEffect } from "react";
import useDebounce from "./useDebounce";
import { City } from "../Models";

type FetchFunction = (
  searchTerm: string,
  pageNumber: number,
  pageSize?: number,
  urlSearch?: string,
) => Promise<City[]>;

const useFetchCities = (
  fetchFunction: FetchFunction,
  initialSearchTerm = "",
  initialPageNumber = 1,
  initialPageSize = 15,
  debounceDelay = 500,
) => {
  const [data, setData] = useState<City[]>([]);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [prevSearchTerm, setPrevSearchTerm] = useState(initialSearchTerm);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await fetchFunction(searchTerm, pageNumber, pageSize);

      setData((prevData) => {
        if (searchTerm !== prevSearchTerm) {
          setPageNumber(1);
          setPrevSearchTerm(searchTerm);
          return result;
        }
        const newRecords = result.filter(
          (newRecord) =>
            !prevData.some((oldRecord) => oldRecord.id === newRecord.id),
        );
        return [...prevData, ...newRecords];
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [searchTerm, pageNumber]);

  useDebounce(fetchData, [searchTerm, pageNumber], debounceDelay);

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return {
    data,
    loading,
    searchTerm,
    setSearchTerm,
    setPageNumber,
    setPageSize,
  };
};

export default useFetchCities;
