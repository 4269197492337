import { useState } from "react";
import { City, dropDownValueType } from "../Models";

const useCityandState = () => {
  const [cities, setCities] = useState<City[]>([]);
  const [state, setState] = useState<dropDownValueType>();
  const [states, setStates] = useState<dropDownValueType[]>([]);
  const [tempState, setTempState] = useState<dropDownValueType>();

  return {
    cities,
    setCities,
    state,
    setState,
    states,
    setStates,
    tempState,
    setTempState,
  };
};

export default useCityandState;
