import { useState, useCallback, useEffect } from "react";

import useDebounce from "./useDebounce";
import { City, Company, State, University } from "../Models";

interface Institution {
  id: string;
  name: string;
  state?: State;
  city?: City;
  url: string;
  isConfirmed?: boolean;
}

type FetchFunction = (
  searchTerm: string,
  pageNumber: number,
  pageSize?: number,
  urlSearch?: string,
) => Promise<Institution[]>;

const useFetchInstitutions = (
  fetchFunction: FetchFunction,
  initialSearchTerm = "",
  initialPageNumber = 1,
  initialPageSize = 15,
  initialUrlSearch = "",
  debounceDelay = 500,
) => {
  const [data, setData] = useState<University[] | Company[]>([]);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [urlSearch, setUrlSearch] = useState(initialUrlSearch);
  const [prevSearchTerm, setPrevSearchTerm] = useState(initialSearchTerm);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    if (searchTerm.length <= 3 || urlSearch.length <= 3) {
      setData([]);
    }
    if (searchTerm.length >= 3) {
      setLoading(true);
      try {
        const result = await fetchFunction(
          searchTerm,
          pageNumber,
          pageSize,
          urlSearch,
        );

        setData((prevData) => {
          if (searchTerm !== prevSearchTerm) {
            setPageNumber(1);
            setPrevSearchTerm(searchTerm);
            return result;
          }
          const newRecords = result.filter(
            (newRecord) =>
              !prevData.some((oldRecord) => oldRecord.id === newRecord.id),
          );
          return [...prevData, ...newRecords];
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } else if (urlSearch.length >= 3) {
      setLoading(true);
      try {
        const result = await fetchFunction(
          searchTerm,
          pageNumber,
          pageSize,
          urlSearch,
        );

        setData((prevData) => {
          if (urlSearch) {
            setPageNumber(1);
            setPrevSearchTerm(searchTerm);
            return result.filter((r) => r.url === urlSearch);
          }
          const newRecords = result.filter(
            (newRecord) =>
              !prevData.some((oldRecord) => oldRecord.id === newRecord.id),
          );
          return [...prevData, ...newRecords];
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [searchTerm, pageNumber, urlSearch]);

  useDebounce(fetchData, [searchTerm, pageNumber, urlSearch], debounceDelay);

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return {
    data,
    loading,
    searchTerm,
    setSearchTerm,
    setPageNumber,
    setPageSize,
    setUrlSearch,
  };
};
export default useFetchInstitutions;
