import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { idToken } from "../Models";

type JwtPayload = {
  exp: number;
};

export default function useIdToken() {
  const [idToken, setIdToken] = useState<idToken | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedIdToken = localStorage.getItem("idToken");
    if (storedIdToken) {
      const parsedToken: idToken = JSON.parse(storedIdToken);
      try {
        const decodedToken: JwtPayload = jwtDecode(parsedToken.token);
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem("idToken");
          navigate("/candidate/login");
        } else {
          setIdToken(parsedToken);
        }
      } catch (error) {
        localStorage.removeItem("idToken");
        navigate("/candidate/login");
      }
    }
  }, [navigate]);

  return idToken;
}
