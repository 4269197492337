import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { isSameMonth, addDays, addMonths } from "date-fns";
import { CalendarDaysIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import SearchDropDown from "../../shared/SearchDropDown";
import { InputField } from "../../shared/InputField";
import ToggleSwitch from "../../shared/ToggleSwitch";
import useEducationState from "../../../hooks/useEducationState";
import useCityandState from "../../../hooks/useCityandState";
import NewInstitutionEntryModal from "../../shared/NewInstitutionEntryModal";
import { GetUniversities } from "../../../WebCalls";
import ReactTooltip from "../../shared/ReactTooltip";
import { University } from "../../../Models";
import { addSeventeenYears, hasExpectedGradDate } from "../../../Helpers";

type EducationFieldsType = {
  educationState: ReturnType<typeof useEducationState>;
  cityandState: ReturnType<typeof useCityandState>;
  inEditMode: boolean;
  selectedCareerId: string;
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
  dob: Date | null;
};

export const EducationFields = ({
  educationState,
  cityandState,
  inEditMode,
  selectedCareerId,
  setOpenError,
  dob,
}: EducationFieldsType) => {
  const [showInstitutionEntry, setShowInstitutionEntry] = useState(false);
  const [universitiesData, setUniversitiesData] = useState<University[]>([]);
  const [errors, setErrors] = useState({
    period: {
      touched: false,
      error: "Please enter the duration of your education.",
    },
    weeklyAvailability: {
      touched: false,
      error: "Weekly Availability is required.",
    },
  });
  const {
    isStudent,
    setIsStudent,
    universityName,
    setUniversityName,
    newUniversityName,
    setNewUniversityName,
    eduStartDate,
    setEduStartDate,
    eduEndDate,
    setEduEndDate,
    expectedGraduation,
    setExpectedGraduation,
    weeklyAvailability,
    setWeeklyAvailability,
    setIsPostgraduate,
    isRelevantEducation,
    setIsRelevantEducation,
    relevantEducation,
    setRelevantEducation,
    newUniversityUrl,
    setnewUniversityUrl,
    degreeType,
    setDegreeType,
    univCity,
    setUnivCity,
    univClosestCity,
    setUnivClosestCity,
    univSubCity,
    setUnivSubCity,
    showDescription,
    setShowDescription,
    education,
  } = educationState;

  const { states, tempState, setTempState, setState } = cityandState;

  const tooltipConfigs = [
    {
      id: "relevant-education-tooltip",
      content:
        "Is your degree directly related to the requirements of the job?",
    },
    {
      id: "availability-tooltip",
      content:
        "Please provide a ballpark figure. Timings will be discussed more specifically during the interview.",
    },
    {
      id: "description-tooltip",
      content: "Provide additional details about your educational background.",
    },
  ];

  // const {
  //   data: universitiesData,
  //   setPageNumber,
  //   setSearchTerm,
  // } = useFetchInstitutions(GetUniversities);
  useEffect(() => {
    const fetchUniversities = async () => {
      const data: University[] = await GetUniversities("", 1, 500);
      setUniversitiesData(data.filter((u) => u.state?.id === tempState?.id));
    };
    fetchUniversities();
  }, [tempState]);

  const handleSetDegreeType = (value: any) => {
    setDegreeType(value);
    setIsPostgraduate(value?.name.toLowerCase() === "postgraduate");
  };

  const handleNewUniNameClose = () => {
    setNewUniversityName("");
    setnewUniversityUrl("");
    setUnivSubCity("");
    setUnivCity({ id: "", name: "" });
    setUniversityName({ id: "", name: "" });
    setTempState({ id: "", name: "" });
    setState({ id: "", name: "" });
  };
  return (
    <div className="space-y-4 lg:grid lg:space-y-0 lg:grid-cols-6 lg:gap-x-6 gap-y-4">
      {(hasExpectedGradDate(education) === selectedCareerId ||
        hasExpectedGradDate(education) === null) && (
        <div className="col-span-6">
          <div className="flex gap-2">
            <label
              htmlFor="student"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-base">Are you currently a student?</span>
              <span className="text-red-600 pl-1">*</span>
            </label>
            <ToggleSwitch
              id="student"
              checked={isStudent}
              setState={(v) => {
                setIsStudent(v);
                setEduEndDate(null);
                setExpectedGraduation(null);
                setErrors((prev) => ({
                  ...prev,
                  period: {
                    ...prev.period,
                    error: "Please enter the duration of your education.",
                    touched: true,
                  },
                }));
              }}
            />
          </div>
        </div>
      )}
      {universityName?.name.toLowerCase() !== "other" && (
        <div className="col-span-1 flex items-end">
          <SearchDropDown
            label="State"
            placeHolder="Select State"
            dropDownValues={states}
            state={tempState}
            setState={(value) => {
              setTempState(value);
              setUniversityName({ id: "", name: "" });
            }}
            mandatoryField
            hideOthers
            error="Please select a state."
          />
        </div>
      )}
      {/* Dropdown Universities */}
      {universityName && universityName.name.toLowerCase() !== "other" && (
        <div className="col-span-3 flex items-end w-full">
          <SearchDropDown
            label="University"
            placeHolder="Select your University..."
            dropDownValues={universitiesData}
            state={universityName}
            setState={setUniversityName}
            mandatoryField
            hideOthers
            hyperLink={{
              label: "Can't find yours? Click here to add it.",
              action: () => {
                setShowInstitutionEntry(true);
              },
            }}
            // apiRequest={{
            //   setPageNumber,
            //   setSearchTerm,
            // }}
            disable={tempState?.id.trim() === ""}
            error="Please select a university."
          />
        </div>
      )}
      {universityName && universityName.name.toLowerCase() === "other" && (
        <div className="relative col-span-2 flex items-end justify-end w-full">
          <InputField
            label="University"
            value={newUniversityName}
            mandatoryField
            isDisabled
          />
          <XCircleIcon
            onClick={handleNewUniNameClose}
            className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
          />
        </div>
      )}
      <div
        className={`flex items-end ${isStudent ? "col-span-2" : "col-span-2"}`}
      >
        <SearchDropDown
          label="Degree Type"
          placeHolder="Choose Degree Type"
          dropDownValues={[
            { id: "1", name: "Undergraduate" },
            { id: "2", name: "Postgraduate" },
          ]}
          state={degreeType}
          setState={handleSetDegreeType}
          dropDownOnly
          hideOthers
          mandatoryField
          error="Please select a degree type."
        />
      </div>
      <div
        className={`flex flex-col justify-end relative ${
          isStudent ? "col-span-1" : "col-span-2"
        }`}
      >
        <label className="text-sm font-medium text-gray-900">
          Period
          <span className="text-red-600 pl-1">*</span>
        </label>
        <div className="flex flex-col sm:flex-row pt-2 gap-6">
          <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
            <label htmlFor="edu-start-date">
              <span className="w-10 sm:w-fit flex items-center h-8">From</span>
            </label>
            <ReactDatePicker
              id="edu-start-date"
              className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm leading-5"
              selected={eduStartDate}
              minDate={dob && addSeventeenYears(dob)}
              maxDate={inEditMode ? eduEndDate : new Date()}
              onChange={(date) => date && setEduStartDate(addDays(date, 1))}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="eg. 01/2021"
              closeOnScroll
              showIcon
              icon={
                <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
              }
              calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
              onFocus={(e) => {
                if (/iPhone|iPad/i.test(navigator.userAgent)) {
                  e.target.readOnly = true;
                }
              }}
              onBlur={() =>
                setErrors((prev) => ({
                  ...prev,
                  period: {
                    ...prev.period,
                    touched: true,
                  },
                }))
              }
            />
          </div>
          {!isStudent && (
            <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
              <label htmlFor="edu-end-date">
                <span className="w-10 sm:w-fit flex items-center h-8">To</span>
              </label>
              <ReactDatePicker
                id="edu-end-date"
                className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm leading-5"
                selected={
                  eduEndDate && eduEndDate > new Date() ? null : eduEndDate
                }
                onChange={(date) => {
                  date && setEduEndDate(addDays(date, 1));
                  setErrors((prev) => ({
                    ...prev,
                    period: {
                      ...prev.period,
                      error: !date
                        ? "Please enter the duration of your education."
                        : "",
                    },
                  }));
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                minDate={eduStartDate}
                maxDate={new Date()}
                filterDate={(date) => {
                  if (eduStartDate) {
                    return !isSameMonth(date, eduStartDate);
                  }
                  return true;
                }}
                placeholderText="eg. 06/2024"
                closeOnScroll
                showIcon
                icon={
                  <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                }
                calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                disabled={!eduStartDate}
                onFocus={(e) => {
                  if (/iPhone|iPad/i.test(navigator.userAgent)) {
                    e.target.readOnly = true;
                  }
                }}
              />
            </div>
          )}
        </div>
        {(!eduStartDate || !eduEndDate || !expectedGraduation) &&
          errors.period.touched &&
          errors.period.error && (
            <p className="absolute -bottom-4 whitespace-nowrap text-xs text-red-500">
              {errors.period.error}
            </p>
          )}
      </div>
      {isStudent && (
        <div className="sm:col-span-2 xl:col-span-3 bg-slate- flex items-end gap-6">
          <div className="text-sm font-medium leading-6 text-gray-900">
            <label htmlFor="expected-grad">
              Expected date of Graduation
              <span className="text-red-600 pl-1">*</span>
            </label>
            <ReactDatePicker
              id="expected-grad"
              className="block mt-2 w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm leading-5"
              selected={expectedGraduation}
              minDate={addMonths(new Date(), 1)}
              onChange={(date) => {
                date && setExpectedGraduation(addDays(date, 1));
                date && setEduEndDate(addDays(date, 1));
                setErrors((prev) => ({
                  ...prev,
                  period: {
                    ...prev.period,
                    error: !date
                      ? "Please enter the duration of your education."
                      : "",
                  },
                }));
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="eg. 04/2025"
              closeOnScroll
              showIcon
              icon={
                <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
              }
              disabled={!eduStartDate}
              onFocus={(e) => {
                if (/iPhone|iPad/i.test(navigator.userAgent)) {
                  e.target.readOnly = true;
                }
              }}
            />
          </div>
          <div className="w-full relative">
            <div className="flex gap-2">
              <label className="flex text-sm font-medium leading-6 text-gray-900">
                Weekly Availability
                <span className="text-red-600 pl-1">*</span>
                <InformationCircleIcon
                  data-tooltip-id="availability-tooltip"
                  className="w-6 h-6 ml-2"
                />
              </label>
            </div>
            <input
              type="number"
              className="mt-2 block w-full font-medium rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm leading-6"
              value={weeklyAvailability}
              onChange={(e) => {
                setWeeklyAvailability(() => parseInt(e.target.value));
                setErrors((prev) => ({
                  ...prev,
                  weeklyAvailability: {
                    ...prev.weeklyAvailability,
                    error:
                      parseInt(e.target.value) === 0 ||
                      e.target.value.trim() === ""
                        ? "Please enter the duration of your education."
                        : "",
                  },
                }));
              }}
              placeholder="eg. 8"
              min={0}
              onBlur={() =>
                setErrors((prev) => ({
                  ...prev,
                  weeklyAvailability: {
                    ...prev.weeklyAvailability,
                    touched: true,
                  },
                }))
              }
            />
            {!weeklyAvailability &&
              errors.weeklyAvailability.touched &&
              errors.weeklyAvailability.error && (
                <p className="absolute -bottom-4 whitespace-nowrap text-xs text-red-500">
                  {errors.weeklyAvailability.error}
                </p>
              )}
          </div>
        </div>
      )}
      <div
        className={`flex flex-col justify-end ${
          isStudent ? "sm:col-span-3 xl:col-span-2" : "col-span-3"
        }`}
      >
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-0 items-end w-full">
          <div className="flex gap-2 w-full">
            <label
              htmlFor="relevanteedu"
              className="flex text-sm font-medium leading-6 text-gray-900"
            >
              Relevant to Job
              <InformationCircleIcon
                data-tooltip-id="relevant-education-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <ToggleSwitch
              id="relevantedu"
              checked={isRelevantEducation}
              setState={setIsRelevantEducation}
            />
          </div>
          {/* <div className="flex gap-2 w-full flex-col">
            <label
              htmlFor="adddescription"
              className="flex text-sm font-medium leading-6 text-gray-900"
            >
              Add Description
              <InformationCircleIcon
                data-tooltip-id="description-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <ToggleSwitch
              id="adddescript"
              checked={showDescription}
              setState={setShowDescription}
            />
          </div> */}
        </div>
        {showDescription && (
          <textarea
            rows={1}
            className="mt-2 block w-full font-medium rounded-md border-0 py-1.5 sm:py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm sm:leading-6"
            value={relevantEducation}
            onChange={(e) => setRelevantEducation(e.target.value)}
            placeholder="Please provide any comments here"
          />
        )}
      </div>
      {tooltipConfigs.map((tooltip) => (
        <ReactTooltip
          key={tooltip.id}
          id={tooltip.id}
          content={tooltip.content}
        />
      ))}
      <NewInstitutionEntryModal
        title="Add University Details"
        type="uni"
        open={showInstitutionEntry}
        setOpen={setShowInstitutionEntry}
        cityandState={cityandState}
        setOpenError={setOpenError}
        inEditMode={inEditMode}
        institutionsFields={{
          institutionName: universityName,
          setInstitutionName: setUniversityName,
          newInstitutionName: newUniversityName,
          setNewInstitutionName: setNewUniversityName,
          newInstitutionUrl: newUniversityUrl,
          setNewInstitutionUrl: setnewUniversityUrl,
          institutionCity: univCity,
          setInstitutionCity: setUnivCity,
          instClosestCity: univClosestCity,
          setInstClosestCity: setUnivClosestCity,
          instSubCity: univSubCity,
          setInstSubCity: setUnivSubCity,
        }}
      />
    </div>
  );
};
