import React from "react";

type ToggleSwitchTypes = {
  id: string;
  checked?: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ToggleSwitch({
  id,
  checked,
  setState,
}: ToggleSwitchTypes) {
  const handleChange = () => {
    setState((prevState) => !prevState);
  };
  return (
    <div className="content">
      <label className="switch">
        <input
          type="checkbox"
          id={id}
          className="input"
          checked={checked}
          onChange={handleChange}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
