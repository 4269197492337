import { calculateTotalMonths } from "../../../Helpers";
import {
  TimelineCandidateEducation,
  TimelineCandidateExperience,
  TimelineGap,
} from "../../../Models";
import { useEffect, useMemo, useRef, useState } from "react";

type TimelineItem =
  | TimelineCandidateEducation
  | TimelineCandidateExperience
  | TimelineGap;

interface TimelineType {
  timeline: TimelineItem[];
}

export default function CareerTimeline({ timeline }: TimelineType) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const mobileRef = useRef<HTMLDivElement | null>(null);
  const [timelineContainer, setTimelineContainer] =
    useState<HTMLDivElement | null>(null);

  const getBlockWidth = (months: number) => {
    if (!containerRef.current) return 0;
    return containerRef.current.clientWidth * ((months / 100) * 2);
  };

  const getBlockHeight = (months: number, totalMonths: number) => {
    const containerHeight = mobileRef.current?.clientHeight ?? 600;
    return (months / totalMonths) * containerHeight + 10;
  };

  const timelineArr: TimelineItem[][] = useMemo(() => {
    if (!timelineContainer) return [];

    let totalBlockWidth = 0;
    const tempTimelineArr: TimelineItem[][] = [];
    let tempArr: TimelineItem[] = [];
    timeline.forEach((ele: TimelineItem, i: number) => {
      const blockWidth: number = getBlockWidth(ele.totalMonths);
      let finalBlockWidth: number = blockWidth;

      if ('university' in ele && ele.overlappingExperience.length > 0) {
        let maxDate = new Date(ele.endDate);
    
        ele.overlappingExperience.forEach((overExp) => {
          const overExpEndDate = new Date(overExp.endDate);
          if (overExpEndDate > maxDate) {
            maxDate = overExpEndDate;
          }
        });
    
        const finalMonths = calculateTotalMonths(new Date(ele.startDate), maxDate);
        finalBlockWidth = getBlockWidth(finalMonths);
      }
      totalBlockWidth += finalBlockWidth;

      // const shouldSegment =
      //   timelineContainer?.clientWidth - totalBlockWidth < 50;
      tempArr.push({ ...ele, blockWidth,finalBlockWidth });
      if (i === timeline.length - 1) {
        tempTimelineArr.push(tempArr);
        tempArr = [];
        totalBlockWidth = 0;
      }

    });
    return tempTimelineArr;
  }, [timelineContainer?.clientWidth, timeline]);

  const timelineArrMobile: TimelineItem[] = useMemo(() => {
    if (!timelineContainer) return [];

    const totalMonths = timeline.reduce(
      (acc: number, item: any) => acc + item.totalMonths,
      0,
    );

    return timeline.map((ele: TimelineItem) => {
      const blockHeight: number = getBlockHeight(ele.totalMonths, totalMonths);

      let finalBlockHeight = blockHeight;

      if ('university' in ele && ele.overlappingExperience.length > 0) {
        let maxDate = new Date(ele.endDate);
    
        ele.overlappingExperience.forEach((overExp) => {
          const overExpEndDate = new Date(overExp.endDate);
          if (overExpEndDate > maxDate) {
            maxDate = overExpEndDate;
          }
        });
    
        const finalMonths = calculateTotalMonths(new Date(ele.startDate), maxDate);
        finalBlockHeight = getBlockHeight(finalMonths,totalMonths);
      }

      if ('university' in ele && ele.overlappingExperience && ele.overlappingExperience.length > 0) {
        const overlappingTotalMonths = ele.overlappingExperience.reduce(
          (acc: number, exp: any) => acc + exp.totalMonths,
          0,
        );

        const proportionFactor = blockHeight / (overlappingTotalMonths + 2);

        const overlappingExperience = ele.overlappingExperience.map(
          (exp: any) => ({
            ...exp,
            blockHeight:getBlockHeight(exp.totalMonths,totalMonths),
          }),
        );

        return { ...ele, blockHeight, overlappingExperience, finalBlockHeight};
      }

      return { ...ele, blockHeight, finalBlockHeight };
    });
  }, [mobileRef.current?.clientHeight, timeline]);
  
  useEffect(() => {
    if (!containerRef.current) return;
    setTimelineContainer(containerRef.current);
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className="w-full sm:px-4 pb-10 cursor-default text-xs"
    >
      <div className="hidden lg:block">
        {timelineArr.map((timeline: TimelineItem[], i: number) => (
          <div
            key={`timeline-block-row-${i}`}
            className="flex items-start gap-3"
            style={{
              flexDirection: i % 2 === 0 ? "row" : "row-reverse",
              marginTop: "48px",
              // paddingRight: i % 2 === 0 ? "" : "10px",
            }}
          >
            {i > 0 && <div style={{ minWidth: "20px" }} className="w-5 h-5" />}
            {timeline.map((ele: any, idx: number) => {
              return (
                <div
                  key={`timeline-block-${idx}`}
                  style={{
                    width: `${ele.finalBlockWidth}px`,
                    maxWidth:"100%"
                  }}
                >
                  {(ele.overlappingExperience && ele.overlappingExperience.length>0)?
                  <div
                  style={{
                    width: `${(ele.blockWidth / ele.finalBlockWidth) * 100}%`,
                    maxWidth:"100%"
                  }}
                  >
                  <h4
                    className={`whitespace-normal h-10 text-center font-semibold ${
                      ele.gap ? "h-10" : undefined
                    } ${ele.totalMonths === 1 && "flex justify-center"}`}
                    title={
                      ele.title ? ele?.company?.name : ele?.university?.name
                    }
                  >
                    {ele.title ? ele?.company?.name : ele?.university?.name}
                  </h4>
                  <h4
                    className={`w-10 h-10 text-white font-semibold rounded-full flex justify-center items-center mx-auto mb-2 ${
                      ele.gap
                        ? "bg-gray-500 relative left-1/2 -translate-x-1/2 lg:static lg:left-0 lg:translate-x-0"
                        : undefined
                    } ${ele.totalMonths === 1 && "-ml-2"}`}
                    style={{
                      background: !ele.gap
                        ? ele.title
                          ? "#0D42EC"
                          : ele.postGrad
                          ? "#e1ad01"
                          : "#F88201"
                        : undefined,
                    }}
                  >
                    {ele.totalMonths}
                  </h4>
                  <div
                    className={`h-3 rounded-full relative ${
                      ele.gap ? "bg-gray-500" : undefined
                    }`}
                    style={{
                      background: !ele.gap
                        ? ele.title
                          ? "#0D42EC"
                          : ele.postGrad
                          ? "#e1ad01"
                          : "#F88201"
                        : undefined,
                    }}
                  />
                  </div>
                  :
                  <>
                    <h4
                    className={`whitespace-normal h-10 text-center font-semibold ${
                      ele.gap ? "h-10" : undefined
                    } ${ele.totalMonths === 1 && "flex justify-center"}`}
                    title={
                      ele.title ? ele?.company?.name : ele?.university?.name
                    }
                  >
                    {ele.title ? ele?.company?.name : ele?.university?.name}
                  </h4>
                  <h4
                    className={`w-10 h-10 text-white font-semibold rounded-full flex justify-center items-center mx-auto mb-2 ${
                      ele.gap
                        ? "bg-gray-500 relative left-1/2 -translate-x-1/2 lg:static lg:left-0 lg:translate-x-0"
                        : undefined
                    } ${ele.totalMonths === 1 && "-ml-2"}`}
                    style={{
                      background: !ele.gap
                        ? ele.title
                          ? "#0D42EC"
                          : ele.postGrad
                          ? "#e1ad01"
                          : "#F88201"
                        : undefined,
                    }}
                  >
                    {ele.totalMonths}
                  </h4>
                  <div
                    className={`h-3 rounded-full relative ${
                      ele.gap ? "bg-gray-500" : undefined
                    }`}
                    style={{
                      background: !ele.gap
                        ? ele.title
                          ? "#0D42EC"
                          : ele.postGrad
                          ? "#e1ad01"
                          : "#F88201"
                        : undefined,
                    }}
                  />
                  </>
                  }
                  {ele.overlappingExperience && (
                    <div className="flex">
                      {ele.overlappingExperience.map(
                        (wrkExp: TimelineCandidateExperience, i: number) => (
                          <div
                            key={i}
                            className="mt-2 relative"
                            style={{
                              width: `${(getBlockWidth(wrkExp.totalMonths)/ele.finalBlockWidth)*100}%`,
                            }}
                          >
                            <div
                              style={{
                                background: wrkExp.company && "#0D42EC",
                                // width: `${getBlockWidth(
                                //   wrkExp.totalMonths,
                                // )}px`,
                              }}
                              className="h-3 rounded-full"
                            />
                            {wrkExp.company && (
                              <h4
                                className={`mt-2 mx-auto w-10 h-10 font-semibold text-white rounded-full flex justify-center items-center ${
                                  wrkExp.totalMonths === 1 && "-ml-2"
                                }`}
                                style={{
                                  background: "#0D42EC",
                                }}
                              >
                                {wrkExp.totalMonths}
                              </h4>
                            )}
                            <h5
                              className={`font-semibold text-center w-full mx-auto absolute ${
                                wrkExp.totalMonths === 1 &&
                                "flex justify-center"
                              }`}
                            >
                              {wrkExp.company?.name}
                            </h5>
                          </div>
                        ),
                      )}
                    </div>
                  )}
                </div>
              );
            })}
            {i < timelineArr.length - 1 && (
              <div
                className="w-5 relative self-end"
                style={{
                  minWidth: "20px",
                  top: timelineArr[i].some(
                    (item: any) => item?.overlappingExperience?.length > 0,
                  )
                    ? "-72px"
                    : "-8px",
                }}
              >
                <div
                  style={{
                    borderWidth: "6px",
                    borderLeft: "none",
                    rotate: i % 2 !== 0 ? "180deg" : "",
                    right: i % 2 !== 0 ? 0 : "",
                    height: timelineArr[i].some(
                      (item: any) => item?.overlappingExperience?.length > 0,
                    )
                      ? "216px"
                      : "150px",
                  }}
                  className="absolute w-10 border-l-0 border-4 border-black rounded-r-full"
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div
        ref={mobileRef}
        style={{ height: "72dvh" }}
        className="lg:hidden flex flex-col gap-2"
      >
        {timelineArrMobile.map((ele: any, idx: number) => {
          return (
            <div
              key={`timeline-block-${idx}`}
              style={{
                height: `${ele.finalBlockHeight}px`,
                maxHeight:"100%"
              }}
              className={`flex gap-2 ${
                ele.overlappingExperience? "justify-center":"justify-end"
              }`}
            >
              {ele.overlappingExperience?.length > 0 && (
                <div className="flex flex-col h-[100%]">
                  {ele.overlappingExperience.map(
                    (wrkExp: TimelineCandidateExperience, i: number) => (
                      <div
                        key={`timeline-block-${i}`}
                        style={{
                          height: `${(wrkExp.blockHeight! / ele.finalBlockHeight!) * 100}%`,
                        }}
                        className="flex flex-row-reverse gap-2"
                      >
                        <div
                          className={`w-2 rounded-full`}
                          style={{
                            background: wrkExp?.company && "#0D42EC",
                          }}
                        />
                        {wrkExp?.company && (
                          <div className="flex flex-row-reverse gap-2 items-center relative">
                            <div>
                              <h4
                                className={`w-7 h-7 text-[10px] text-white font-medium rounded-full flex justify-center items-center mx-auto`}
                                style={{
                                  background: "#0D42EC",
                                }}
                              >
                                {wrkExp.totalMonths}
                              </h4>
                            </div>
                            <h4
                              className={`w-1/2 text-right mr-1.5 font-medium`}
                              title={wrkExp?.company?.name}
                            >
                              {wrkExp?.company?.name}
                            </h4>
                          </div>
                        )}
                      </div>
                    ),
                  )}
                </div>
              )}

              {(ele.overlappingExperience && ele.overlappingExperience.length>0)?
              <div
              className="flex gap-2"
              style={{
                height:`${(ele.blockHeight/ele.finalBlockHeight)*100}%`,
                maxHeight:"100%"
              }}
              >
                <div
                className={`!w-2 rounded-full ${ele.gap ? "bg-gray-500" : undefined
                  }`}
                style={{
                  background: !ele.gap
                    ? ele.title
                      ? "#0D42EC"
                      : ele.postGrad
                        ? "#e1ad01"
                        : "#F88201"
                    : undefined,
                }}
              />

              <div
                className={`flex gap-2 items-center ${timelineArrMobile.some(
                  (item: any) => item?.overlappingExperience?.length > 0,
                )
                    ? "w-1/2"
                    : "w-full"
                  }`}
              >
                <div>
                  <h4
                    className={`w-7 h-7 text-[10px] text-white font-medium rounded-full flex justify-center items-center mx-auto ${ele.gap ? "bg-gray-500" : undefined
                      }`}
                    style={{
                      background: !ele.gap
                        ? ele.title
                          ? "#0D42EC"
                          : ele.postGrad
                            ? "#e1ad01"
                            : "#F88201"
                        : undefined,
                    }}
                  >
                    {ele.totalMonths}
                  </h4>
                </div>
                <h4
                  className={`text-left font-medium flex items-center`}
                  title={ele.title ? ele?.company?.name : ele?.university?.name}
                >
                  {ele.title ? ele?.company?.name : ele?.university?.name}
                </h4>
              </div>
              </div>
              :
              <>
              <div
                className={`!w-2 rounded-full ${ele.gap ? "bg-gray-500" : undefined
                  }`}
                style={{
                  background: !ele.gap
                    ? ele.title
                      ? "#0D42EC"
                      : ele.postGrad
                        ? "#e1ad01"
                        : "#F88201"
                    : undefined,
                }}
              />

              <div
                className={`flex gap-2 items-center ${timelineArrMobile.some(
                  (item: any) => item?.overlappingExperience?.length > 0,
                )
                    ? "w-1/2"
                    : "w-full"
                  }`}
              >
                <div>
                  <h4
                    className={`w-7 h-7 text-[10px] text-white font-medium rounded-full flex justify-center items-center mx-auto ${ele.gap ? "bg-gray-500" : undefined
                      }`}
                    style={{
                      background: !ele.gap
                        ? ele.title
                          ? "#0D42EC"
                          : ele.postGrad
                            ? "#e1ad01"
                            : "#F88201"
                        : undefined,
                    }}
                  >
                    {ele.totalMonths}
                  </h4>
                </div>
                <h4
                  className={`text-left font-medium flex items-center`}
                  title={ele.title ? ele?.company?.name : ele?.university?.name}
                >
                  {ele.title ? ele?.company?.name : ele?.university?.name}
                </h4>
              </div>
              </>

              }
            </div>
          );
        })}
      </div>
    </div>
  );
}
