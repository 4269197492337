import { useEffect, useState } from "react";
import { Candidate, City, Education, Experience } from "../Models";
import { GetCandidate, GetCities } from "../WebCalls";
import useIdToken from "./useIdToken";

export default function useCandidateProfile() {
  const [cities, setCities] = useState<City[]>([]);
  const [candidate, setCandidate] = useState<Candidate>();
  const idToken = useIdToken();

  useEffect(() => {
    GetCities().then((data) => setCities(data));
  }, []);

  useEffect(() => {
    if (!idToken) return;
    GetCandidate(idToken.candidateId, idToken.token).then((res) => {
      let mappedExperience: Experience[] = [];
      let mappedEducation: Education[] = [];
      if (res.experience) {
        mappedExperience = res.experience.map((exp: any) => ({
          id: exp.id,
          companyName: exp.companyName,
          title: exp.title,
          period: exp.period,
          description: exp.description,
          company: exp.company,
          relevant: exp.relevant,
          startDate: exp.startDate ? new Date(exp.startDate) : null,
          endDate: exp.endDate ? new Date(exp.endDate) : null,
        }));
      }
      if (res.education) {
        mappedEducation = res.education.map((edu: any) => ({
          id: edu.id,
          school: edu.school,
          degree: edu.degree,
          fieldofstudy: edu.fieldOfStudy,
          period: edu.period,
          startDate: edu.startDate ? new Date(edu.startDate) : null,
          endDate: edu.endDate ? new Date(edu.endDate) : null,
          university: edu.university,
          postGrad: edu.postGrad,
          relevant: edu.relevant,
        }));
      }

      const currentWorkLocations1 = cities.find(
        (city) => city.id === res.workLocation1CityId,
      );
      const currentWorkLocations2 = cities.find(
        (city) => city.id === res.workLocation2CityId,
      );

      const candidateData: Candidate = {
        id: res.id,
        firstName: res.firstName,
        lastName: res.lastName,
        dateOfBirth: res.dateOfBirth ? new Date(res.dateOfBirth) : null,
        email: res.email,
        address: res.address,
        cv: res.cv,
        linkedinProfile: res.linkedinProfile ?? "",
        coverLetter: res.coverLetter,
        education: mappedEducation,
        experience: mappedExperience,
        noticePeriod: res.noticePeriod,
        weeklyAvailability: res.weeklyAvailability,
        yearsOfExperience: res.yearsOfExperience,
        expectedGraduationDate: res.expectedGraduationDate
          ? new Date(res.expectedGraduationDate)
          : null,
        workLocation1: currentWorkLocations1?.name || "",
        workLocation1CityId: res.workLocation1CityId,
        workLocation2: currentWorkLocations2?.name || "",
        workLocation2CityId: res.workLocation2CityId,
      };
      setCandidate(candidateData);
    });
  }, [idToken, cities]);

  return candidate;
}
