import React, { useState, useEffect } from "react";
import AssignmentReview from "./AssignmentReview";
import { useParams } from "react-router-dom";
import { submitAssignmentLink, GetCandidateData } from "../../../../WebCalls";

const AssignmentSubmission: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);
  const [githubLink, setGithubLink] = useState("");
  const { roleId, candidateId } = useParams();
  const [review, setReview] = useState("");
  const [validgit,setvalidgit]=useState(true)

  const checkAssignmentOnPageLoad = async () => {
    if (!candidateId || !roleId) {
      return;
    }

    try {
      const candidateData = await GetCandidateData(candidateId, roleId);
      if (candidateData.assignmentLink != null) {
        setSubmitted(true);
        setReview(candidateData.comments);
      }
    } catch (error) {
      console.error("Error checking assignment submission:", error);
    }
  };

  useEffect(() => {
    checkAssignmentOnPageLoad();
  }, []);

  const handleSubmit = async () => {
    const regex = /^https?:\/\/(?:www\.)?github\.com\/(?:[a-zA-Z0-9_-]+(?:\/[a-zA-Z0-9_-]+)?)?$/;
    const isValidGithubLink = regex.test(githubLink);
    setvalidgit(isValidGithubLink)


    if (githubLink.trim() !== "") {
      if (!candidateId || isValidGithubLink==false) {
        return;
      }

      try {
        await submitAssignmentLink({
          candidateId,
          roleId,
          assignmentLink:githubLink,
          comments: ""
        });
        setSubmitted(true);
      } catch (error) {
        console.error("Error handling assignment submission:", error);
      }
    } 
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGithubLink(event.target.value);
  };

  return (
    <div className="p-6 w-screen mb-36">
      <div className="flex items-center justify-center h-32 bg-gray-50 ">
        <h1 className="font-semibold text-center text-2xl">Assignment Submission</h1>
      </div>

      <div className="flex justify-center p-4 rounded-md mt-10 ">
        <div className="flex flex-col items-center justify-center space-y-4 w-full md:w-1/2 mt-0">
          {submitted ? (
            <>
              <AssignmentReview review={review} />
            </>
          ) : (
            <>
              <h1 className="font-semibold">
                Submit your assignment GitHub link so we can review your assignment
              </h1>
              <input
                type="text"
                placeholder="Enter GitHub link"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                value={githubLink}
                onChange={handleInputChange}
              />
              {validgit?(
                <>
                </>
              ):(
                 <p className="text-sm text-red-700">Please enter valid githubLink</p>
              )

              }
              <button
                className="inline-flex cursor-pointer items-center justify-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-slate-200 disabled:cursor-not-allowed"
                onClick={handleSubmit}
               
                disabled={githubLink.trim() === ""}
              >
                Submit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignmentSubmission;
