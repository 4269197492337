import React, { useEffect, useMemo, useRef, useState } from "react";
interface TimelineEvent {
  [key: string]: {
    date: string;
    color: string;
    radius: number;
    text: string;
    id: string;
    dependsOn: string;
  };
}
const dateConverter = (date: string, withTime?: boolean): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  if (withTime) {
    options.hour = "numeric";
    options.minute = "numeric";
    options.hour12 = true;
  }
  const convertedDate = new Date(date).toLocaleDateString("en-US", options);
  return convertedDate;
};
const RecruitmentTimeline = ({
  timelineData,
  timelineStatus,
  getExpandedData,
}: {
  timelineStatus: any[];
  timelineData: TimelineEvent[];
  getExpandedData: Function;
}) => {
  const [hoveredCircle, setHoveredCircle] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [timelineContainer, setTimelineContainer] =
    useState<HTMLDivElement | null>(null);

  function calculateDayDifference(startDate: Date, endDate: Date) {
    const startUTC = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
    );
    const endUTC = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
    );

    const millisecondsDifference = endUTC - startUTC;

    const dayDifference = Math.ceil(
      millisecondsDifference / (1000 * 60 * 60 * 24),
    );

    return dayDifference;
  }

  const timelineArr: any[][] = useMemo(() => {
    if (!timelineContainer) return [];

    let totalBlockWidth = 0;
    const tempTimelineArr: any[][] = [];
    let tempArr: any[] = [];
    const dates = timelineData.map((item, index) => {
      const key = Object.keys(item)[0];
      return item[key].date;
    });
    const filteredTimelineStatus = timelineStatus.filter(
      (status) => status !== "roleCreated" && status !== "Job Posted",
    );
    let dependsOnValues = new Set(
      timelineData.map((item) => Object.values(item)[0].dependsOn),
    );
    let notDependsOn: any[] = filteredTimelineStatus.filter(
      (status) => !dependsOnValues.has(status),
    );
    if (dependsOnValues.has("Disqualified")) {
      notDependsOn = [];
    }
    if (dependsOnValues.has("Offered")) {
      const index = notDependsOn.findIndex((e) => e === "Disqualified");
      if (index !== -1) {
        notDependsOn.splice(index, 1);
      }
    }

    if (
      notDependsOn.includes("Offered") &&
      notDependsOn.includes("Disqualified")
    ) {
      const index = notDependsOn.findIndex((e) => e === "Disqualified");
      if (index !== -1) {
        notDependsOn.splice(index, 1);
      }
    }
    let arr = [...timelineData, ...notDependsOn];
    arr.forEach((ele: any, i: number) => {
      if (i !== 0) {
        let blockWidth: number = 0;

        if (i >= timelineData.length) {
          totalBlockWidth += 65;
          tempArr.push({
            blockWidth: 65,
            color: "gray",
            date: "Not Applicable",
            dependsOn: ele,
            id: ele,
            radius: 50,
            text: ele,
            dayDifference: "",
          });
        } else {
          const strictDaysDiff = calculateDayDifference(
            new Date(dates[i - 1]),
            new Date(dates[i])
          );
          // const millisecondsDifference = differenceInMilliseconds(dates[i], dates[i - 1]);
          // const dayDifference = millisecondsDifference / (1000 * 60 * 60 * 24);

          if (strictDaysDiff > 0) {
            blockWidth = (strictDaysDiff + 1) * 65;
          } else {
            blockWidth = 65;
          }
          totalBlockWidth += blockWidth;
          tempArr.push({
            ...ele[Object.keys(ele)[0]],
            blockWidth,
            dayDifference: calculateDayDifference(
              new Date(dates[i - 1]),
              new Date(dates[i])
            ),
          });
        }
        const shouldSegment =
          timelineContainer?.clientWidth - totalBlockWidth < 50;
        if (shouldSegment || i === arr.length - 1) {
          tempTimelineArr.push(tempArr);
          tempArr = [];
          totalBlockWidth = 0;
        }
      }
    });

    // notDependsOn.map((e, i) => {
    //     totalBlockWidth += 100;
    //     const shouldSegment =
    //         timelineContainer?.clientWidth - totalBlockWidth < 50;

    //     tempArr.push({ blockWidth: 100, color: "gray", date: "Not Applicable", dependsOn: e, id: e, radius: 50, text: e, dayDifference: "" });
    //     if (shouldSegment || i === notDependsOn.length - 1) {
    //         tempTimelineArr.push(tempArr);
    //         tempArr = [];
    //         totalBlockWidth = 0;
    //     }
    // })

    return tempTimelineArr;
  }, [timelineContainer, timelineData, timelineStatus]);

  useEffect(() => {
    if (!containerRef.current) return;
    setTimelineContainer(containerRef.current);
  }, []);

  return (
    <div
      ref={containerRef}
      className="w-full sm:px-4 -mt-20 sm:-mt-10 sm:pb-5 h-full cursor-default"
    >
      {timelineArr.map((timeline: any[], i: number) => (
        <div
          key={`timeline-block-row-${i}`}
          className="flex items-start gap-3"
          style={{
            flexDirection: i % 2 === 0 ? "row" : "row-reverse",
            marginTop: "48px",
          }}
        >
          {i > 0 && <div className="w-5 h-5 min-w-5" />}
          {timeline.map((ele: any, idx: number) => {
            return (
              <div
                className="cursor-pointer "
                title={`${ele?.id}\n${ele.date === "Not Applicable" ? "Not Applicable" : dateConverter(ele?.date, true)}`}
                key={`timeline-block-${idx}`}
                style={{
                  width: `${ele.blockWidth}px`,
                }}
                onMouseEnter={() => setHoveredCircle(ele.id)}
                onMouseLeave={() => setHoveredCircle(null)}
                onClick={() => {
                  if (
                    ele.id === "Assessment Done" ||
                    ele.id === "Assignment Submitted"
                  ) {
                    getExpandedData(ele.id);
                  }
                }}
              >
                {ele.id === hoveredCircle ? (
                  <>
                    <h4
                      className={`text-xs mb-3 h-8 whitespace-break-spaces overflow-hidden font-medium text-center`}
                    >
                      {ele.text}
                    </h4>
                    <div className="h-14 w-full ">
                      <h4
                        className={`w-12 h-12 text-white font-semibold rounded-full flex justify-center ${ele.date === "Not Applicable" ? " border-dotted border-2" : ""} ${ele.id === "Assessment Done" && parseInt(ele.text.match(/\d+/)[0]) >= 70 ? "border-4 border-green-400" : ""} items-center mx-auto mb-2 }`}
                        style={{
                          background: ele?.color,
                        }}
                      >
                        {ele.dayDifference}
                      </h4>
                    </div>
                  </>
                ) : (
                  <>
                    <h4
                      className={`text-xs mb-3 h-8 whitespace-break-spaces overflow-hidden font-medium text-center`}
                    >
                      {ele.text}
                    </h4>
                    <div className="h-14 w-full">
                      <h4
                        className={`w-10 h-10 text-white font-semibold rounded-full flex justify-center ${ele.id === "Assessment Done" && parseInt(ele.text.match(/\d+/)[0]) >= 70 ? "border-4 border-green-400" : ""} items-center mx-auto mb-2 ${ele.date === "Not Applicable" ? " border-dotted border-2" : ""}`}
                        style={{
                          background: ele?.color,
                        }}
                      >
                        {ele.dayDifference}
                      </h4>
                    </div>
                  </>
                )}
                <div
                  className={`h-3 rounded-full ${
                    ele.gap ? "bg-gray-500" : undefined
                  }`}
                  style={{
                    background: ele?.color,
                  }}
                />
              </div>
            );
          })}
          {i < timelineArr.length - 1 && (
            <div
              className="w-5 relative self-end"
              style={{
                minWidth: "20px",
                top: "-8px",
              }}
            >
              <div
                style={{
                  borderWidth: "6px",
                  borderLeft: "none",
                  rotate: i % 2 !== 0 ? "180deg" : "",
                  right: i % 2 !== 0 ? 0 : "",
                  height: "164px",
                }}
                className="absolute w-10 border-l-0 border-4 border-gray-600 rounded-r-full"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RecruitmentTimeline;
