import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";

import { City, dropDownValueType } from "../../../Models";
import SearchDropDown from "../../shared/SearchDropDown";
import ConfirmModal from "../../shared/ConfirmModal";
import { InputField } from "../../shared/InputField";

type NearestLocationPropType = {
  states: dropDownValueType[];
  cities: City[];
  workLocations: City[];
  setWorkLocations: React.Dispatch<React.SetStateAction<City[]>>;
  showSubcityConfirmation: boolean;
  setShowSubcityConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  existingLocation: boolean;
  setExistingLocation: React.Dispatch<React.SetStateAction<boolean>>;
  onBlur?: () => void;
};

export default function NearestLocation({
  states,
  cities,
  workLocations,
  setWorkLocations,
  showSubcityConfirmation,
  setShowSubcityConfirmation,
  existingLocation,
  setExistingLocation,
  onBlur,
}: NearestLocationPropType) {
  const [state, setState] = useState<dropDownValueType | undefined>({
    id: "",
    name: "",
  });
  const [newNearestLocation, setNewNearestLocation] = useState<
    dropDownValueType | undefined
  >({
    id: "",
    name: "",
  });

  const [subCity, setSubCity] = useState<string>("");
  const [tempCities, setTempCities] = useState<dropDownValueType[]>([]);
  const [tempCity, setTempCity] = useState<dropDownValueType | undefined>({
    id: "",
    name: "",
  });

  const modalFields = [
    {
      modalId: 1,
      key: "location-modal",
      type: "success",
      open: showSubcityConfirmation,
      setOpen: setShowSubcityConfirmation,
      title: "Add State & City Name",
      description: "Please select the state along with your city name.",
      buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
    },
    {
      modalId: 2,
      key: "existingLocation-modal",
      type: "danger",
      open: existingLocation,
      setOpen: setExistingLocation,
      title: "Existing Location",
      description:
        "The location you are attempting to add has already been added. Please try adding a different location.",
      buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
    },
  ];

  useEffect(() => {
    setTempCities(cities.filter((city) => city.state?.id === state?.id));
  }, [state]);

  useEffect(() => {
    setShowSubcityConfirmation(
      () => newNearestLocation?.name.toLowerCase() === "other" && true,
    );

    newNearestLocation?.name.toLowerCase() !== "search" &&
      setState({
        id: "",
        name: "",
      });
  }, [newNearestLocation]);

  useEffect(() => {
    checkAndSave();
  }, [newNearestLocation, state]);

  const isExistingWorkLocation = (location: City) => {
    return workLocations.some(
      (loc) =>
        loc.name === location.name &&
        loc.state?.id === location.state?.id &&
        loc.nearestCity === location.nearestCity,
    );
  };

  const checkAndSave = () => {
    if (
      newNearestLocation?.name.trim().length !== 0 &&
      newNearestLocation?.name.toLowerCase() !== "other" &&
      newNearestLocation?.name.toLocaleLowerCase() !== "search"
    ) {
      const nearest = newNearestLocation || { id: "", name: "" };

      const newCity: City = {
        id: nearest?.id,
        name: nearest?.name,
        nearestCity: null,
        state: state,
      };

      if (!isExistingWorkLocation(newCity)) {
        setWorkLocations([...workLocations, newCity]);
      } else {
        setExistingLocation(true);
      }
      setTempCity({ id: "", name: "" });
      setState({ id: "", name: "" });
      setNewNearestLocation({ id: "", name: "" });
    } else if (
      // tempCity?.name?.trim().length !== 0 &&
      subCity.trim().length !== 0 &&
      newNearestLocation?.name.toLowerCase() === "other"
    ) {
      const newCity: City = {
        id: uuidv4(),
        name: subCity,
        nearestCity: null,
        state: state,
        isConfirmed: false,
      };

      if (!isExistingWorkLocation(newCity)) {
        setWorkLocations([...workLocations, newCity]);
      } else {
        setExistingLocation(true);
      }
      setTempCity({ id: "", name: "" });
      setSubCity("");
      setState({ id: "", name: "" });
      setNewNearestLocation({ id: "", name: "" });
    }
  };

  return (
    <div className="w-full " onBlur={onBlur}>
      {newNearestLocation?.name.toLowerCase() === "other" ? (
        <div>
          <div className="w-full">
            {workLocations.length >= 2 ? (
              <></>
            ) : (
              <div className="mt-2">
                <InputField
                  label="Location Name"
                  value={subCity}
                  setValue={setSubCity}
                  placeHolder="eg. Chennai"
                />
              </div>
            )}
          </div>
          <Combobox
            as="div"
            className={`${
              newNearestLocation?.name.toLowerCase() === "other"
                ? "col-span-3"
                : "col-span-full"
            }`}
            value={tempCity}
            onChange={setTempCity}
          >
            <Combobox.Label className="text-sm font-medium text-gray-900">
              State
            </Combobox.Label>
            {workLocations.length >= 2 ? (
              <></>
            ) : (
              <div className="block lg:block sm:flex gap-4">
                <SearchDropDown
                  placeHolder="Select State"
                  dropDownValues={states}
                  state={state}
                  setState={setState}
                  hideOthers
                  disable={subCity.trim().length === 0 && true}
                />

                {/* <SearchDropDown
                  placeHolder="Select Nearest City"
                  dropDownValues={tempCities}
                  state={tempCity}
                  setState={setTempCity}
                  hideOthers
                  disable={subCity.trim().length === 0 && true}
                /> */}
              </div>
            )}
          </Combobox>
        </div>
      ) : (
        <>
          {workLocations.length < 2 && (
            <Combobox
              as="div"
              className={`${
                newNearestLocation?.name.toLowerCase() === "other"
                  ? "col-span-3"
                  : "col-span-full"
              }`}
              value={newNearestLocation}
              onChange={setNewNearestLocation}
            >
              <div className="block lg:block sm:flex gap-4">
                <SearchDropDown
                  placeHolder="Select State"
                  dropDownValues={states}
                  state={state}
                  setState={setState}
                  hideOthers
                />
                <SearchDropDown
                  placeHolder="Select City"
                  dropDownValues={tempCities}
                  state={newNearestLocation}
                  setState={setNewNearestLocation}
                  disable={state?.name === ""}
                />
              </div>
            </Combobox>
          )}
        </>
      )}
      {modalFields.map((modal) => (
        <ConfirmModal
          key={modal.modalId}
          type={modal.type}
          open={modal.open}
          setOpen={modal.setOpen}
          title={modal.title}
          description={modal.description}
          buttonContents={modal.buttonContents}
        />
      ))}
    </div>
  );
}
