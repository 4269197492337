type CheckBoxTypes = {
  id: string;
  label: string;
  checked?: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CheckBox = ({ id, label, checked, setState }: CheckBoxTypes) => {
  const handleChange = () => {
    setState((prevState) => !prevState);
  };
  return (
    <div>
      <label
        htmlFor={id}
        className="flex flex-row items-center gap-2.5 text-xs"
      >
        <input
          id={id}
          type="checkbox"
          className="peer hidden"
          checked={checked}
          onChange={handleChange}
        />
        <div className="h-5 w-5 flex rounded-md border border-gray-300 bg-transparent peer-checked:bg-entntblue transition shadow-lg">
          <svg
            fill="none"
            viewBox="0 0 24 24"
            className="w-5 h-5 stroke-white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
        {label}
      </label>
    </div>
  );
};
