import React, { useState, useEffect } from "react";

interface CommentProps {
  review: any;
}

const AssignmentReview: React.FC<CommentProps> = ({ review }) => {
  

  return (
    <div className="flex flex-col items-center gap-3 w-full">
      <span className="text-3xl font-semibold">Assignment Review</span>
      {review[0]?.length > 0 ? (
        <div>
          <p>
            Thank you for submitting your assignment. Your effort is
            commendable, and we appreciate the time and dedication you've put
            into it. Here are our thoughts on your submission:
          </p>

          <p className="mt-5 border border-blue-400 rounded pl-4 pr-4 pt-5 pb-5 ">
            {review}
          </p>
        </div>
      ) : (
        <p className="text-center mt-5">
            Thank you for submitting your assignment. Your effort is
            commendable,and we appreciate the time and dedication you've put
            into it.we are currently reviewing your assignment. Please allow us some time
            to complete the evaluation process.
        </p>
      )}
    </div>
  );
};

export default AssignmentReview;
