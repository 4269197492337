import React from "react";

export const TimelineColorInfo = () => {
  return (
    <div className="font-medium w-full flex items-center justify-center gap-4">
      <div className="flex gap-1 items-center">
        <div className="h-3 w-3 bg-entntorange rounded-full" />
        <span> Under Graduate</span>
      </div>
      <div className="flex gap-1 items-center">
        <div className="h-3 w-3 bg-[#e1ad01] rounded-full" />
        <span> Post Graduate</span>
      </div>
      <div className="flex gap-1 items-center">
        <div className="h-3 w-3 bg-entntblue rounded-full" />
        <span> Work Experience</span>
      </div>
      <div className="flex gap-1 items-center">
        <div className="h-3 w-3 bg-gray-500 rounded-full" />
        <span> Career Gap</span>
      </div>
    </div>
  );
};
