import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Candidate, CandidateStatus, Role, RoleStatus } from "../../../Models";
import useIdToken from "../../../hooks/useIdToken";
import {
  GetCandidate,
  GetCandidateScore,
  GetStatusForRole,
} from "../../../WebCalls";
import RecruitmentTimelineModal from "./RecruitmentTimelineModal/RecruitmentTimelineModal";

export default function Timeline({
  role,
  candidateStatuses,
  handleCurrentRole,
}: {
  role: Role;
  candidateStatuses: CandidateStatus[];
  handleCurrentRole: (s: Role | null) => void;
}) {
  const [recruitmentTimelineData, setRecruitmentTimelineData] = useState<any[]>(
    [],
  );
  const [candidateStatus, setCandidateStatus] = useState<any[]>([]);
  const [candidate, setCandidate] = useState<Candidate>();
  const [roleStatus, setRoleStatus] = useState<RoleStatus[]>([]);

  const idToken = useIdToken();

  useEffect(() => {
    if (!idToken) return;
    GetCandidate(idToken.candidateId, idToken.token).then((res) => {
      setCandidate(res);
    });
    GetStatusForRole(role.id, idToken.token).then((res) => {
      setRoleStatus(res);
    });
   
  }, [idToken]);

  useEffect(() => {
    if (role) {
      setRecruitmentTimelineData((prevState) => [
        ...prevState.map((item) => {
          if (Object.keys(item)[0] === "roleCreated") {
            return {
              roleCreated: {
                date: role?.date,
                color: "blue",
                radius: 50,
                text: "Job Posted",
              },
            };
          }
          return item;
        }),
      ]);
    }
    if (candidate !== null) {
      const sortedStatuses = candidateStatuses
        ?.filter((cs) => cs.roleId === role.id)
        .sort((a: any, b: any) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);

          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          }

          return a.roleStatus.order - b.roleStatus.order;
        });

      const sortedStatusesInfo = sortedStatuses?.map((status: any) => ({
        name: status.roleStatus?.name,
        date: status.date,
      }));

      sortedStatusesInfo?.unshift({ name: "roleCreated", date: role?.date });
      setCandidateStatus(sortedStatusesInfo);
    }
  }, [role, candidate]);

  return (
    <div className="border border-gray-200 shadow-sm relative mx-12 mt-4 rounded-md w-2/3">
      <div
        onClick={() => {
          handleCurrentRole(null);
        }}
        className="flex items-center space-x-2 mr-2 cursor-pointer absolute right-3 top-2 z-20"
      >
        <ArrowUturnLeftIcon className="h-5" />
      </div>
      <RecruitmentTimelineModal
        timelineData={recruitmentTimelineData}
        role={role}
        status={roleStatus}
        candidateStatus={candidateStatus}
        candidate={candidate}
      />
    </div>
  );
}
