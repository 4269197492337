import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MapPinIcon, XCircleIcon } from "@heroicons/react/20/solid";

import useCityandState from "../../hooks/useCityandState";
import SearchDropDown from "./SearchDropDown";
import { InputField } from "./InputField";
import { City, dropDownValueType } from "../../Models";

type NewCompanyCityType = {
  compCity?: City | undefined;
  setCompCity?: React.Dispatch<React.SetStateAction<City | undefined>>;
  compLocationClosestCity: City | undefined;
  setCompLocationClosestCity: React.Dispatch<
    React.SetStateAction<City | undefined>
  >;
  compLocationSubCity: string;
  setCompLocationSubCity: React.Dispatch<React.SetStateAction<string>>;
};

type props = {
  type: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  cityandState: ReturnType<typeof useCityandState>;
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
  inEditMode?: boolean;
  newCompanyCityType: NewCompanyCityType;
};

export default function AddNewCompanyCity(props: props) {
  const cancelButtonRef = useRef(null);
  const danger = props.type === "danger";
  const [tempCities, setTempCities] = useState<dropDownValueType[]>([]);

  const {
    compLocationClosestCity,
    setCompLocationClosestCity,
    compLocationSubCity,
    setCompLocationSubCity,
  } = props.newCompanyCityType;
  const { cities, states, tempState, setTempState } = props.cityandState;

  useEffect(() => {
    setTempCities(cities.filter((city) => city.state?.id === tempState?.id));
  }, [tempState]);

  const handleSubcityClose = () => {
    if (compLocationSubCity.trim().length !== 0) {
      setCompLocationSubCity("");
    } else {
      setTempState({ id: "", name: "" });
      setCompLocationClosestCity({ id: "", name: "" });
    }
  };

  const validateOnSave = () => {
    const isCityValid = compLocationSubCity.trim().length > 0;

    return !tempState || !isCityValid;
  };

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();

    if (validateOnSave()) {
      // props.setOpenError(true);
      return;
    } else {
      props.setOpen(false);
    }
  };

  const handleCancel = () => {
    if (!props.inEditMode) {
      // setCompCity({ id: "", name: "" });
      setTempState({ id: "", name: "" });
      // setCompLocationClosestCity({ id: "", name: "" });
      setCompLocationSubCity("");
    }
    props.setOpen(false);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        id="institution-enrty-modal"
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          document.getElementById("subcity-modal")?.id !== "subcity-modal" &&
            document.getElementById("error-modal")?.id !== "error-modal" &&
            handleCancel();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-2xl ">
                <div className="bg-white px-4 pt-4 py-2 border-b shadow-sm">
                  <div className="sm:flex sm:items-center">
                    <div
                      className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                        danger ? "bg-red-100" : "bg-blue-100"
                      } sm:mx-0 `}
                    >
                      <MapPinIcon
                        className={`h-6 w-6 ${
                          danger ? "text-red-600" : "text-blue-600"
                        }`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="text-center sm:ml-3 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 text-center"
                      >
                        {props.title}
                      </Dialog.Title>
                    </div>
                  </div>
                </div>
                <div className="px-10 pt-2 pb-4">
                  <div className="space-y-3 lg:space-y-0 lg:grid grid-cols-2 gap-3">
                    {/* Dropdown States */}
                    <div className="flex items-end w-full col-span-1">
                      <SearchDropDown
                        optionsClassName="!max-h-16"
                        label="State"
                        placeHolder="Select State"
                        dropDownValues={states}
                        state={tempState}
                        setState={setTempState}
                        mandatoryField
                        hideOthers
                        error="Please select a state."
                      />
                    </div>

                    {/* New City fields */}
                    {/* <div className="col-span-1">
                      <SearchDropDown
                        optionsClassName="!max-h-28"
                        label="Nearest City"
                        placeHolder="Select City"
                        dropDownValues={tempCities}
                        state={compLocationClosestCity}
                        setState={setCompLocationClosestCity}
                        mandatoryField
                        hideOthers
                        disable={tempState?.name.trim().length === 0}
                      />
                    </div> */}
                    <div className="relative flex items-end gap-6 col-span-1">
                      <InputField
                        label="City Name"
                        value={compLocationSubCity}
                        setValue={setCompLocationSubCity}
                        placeHolder="eg. Chennai"
                        mandatoryField
                        error="Please enter the city name."
                        validate={(v: string) =>
                          v.trim() === "" ? "Please enter the city name." : ""
                        }
                      />
                      <XCircleIcon
                        onClick={handleSubcityClose}
                        className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mx-4 bg-red-500 text-white "
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={validateOnSave()}
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  mx-4 text-white 
                      ${
                        validateOnSave()
                          ? "bg-gray-300"
                          : " bg-entntblue hover:bg-entntorange"
                      }
                      `}
                    onClick={(e) => handleSave(e)}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
