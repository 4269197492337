import { useEffect, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";

import ReactTooltip from "../../shared/ReactTooltip";

type ProfileHeaderType = {
  showEduEdit: boolean;
  setshowEduEdit: React.Dispatch<React.SetStateAction<boolean>>;
  showExpEdit: boolean;
  setShowExpEdit: React.Dispatch<React.SetStateAction<boolean>>;
  clearEduStates: () => void;
  clearExpStates: () => void;
  validatePersonalInfo: boolean;
};

export const ProfileHeader = ({
  showEduEdit,
  showExpEdit,
  setshowEduEdit,
  setShowExpEdit,
  clearEduStates,
  clearExpStates,
  validatePersonalInfo,
}: ProfileHeaderType) => {
  const [error, setError] = useState("");

  const tooltipConfigs = [
    {
      id: "education-tooltip",
      content:
        "Please provide all relevant studies for this current job opening and include past and present education. If your studies are not relevant to the job, kindly indicate by checking the appropriate box.",
    },
    {
      id: "workexperience-tooltip",
      content:
        "Please provide all work experience relevant to this current job opening. We do consider internships as job experience, so please include those as well. Any non-relevant job experience should be indicated by checking the relevant tickbox.",
    },
  ];

  type renderProfileFieldsType = {
    label: string;
    handleClick: () => void;
    showEdit: boolean;
    tooltipId?: string;
    mandatoryField?: boolean;
  };

  const renderProfileFields = ({
    label,
    handleClick,
    showEdit,
    tooltipId,
    mandatoryField,
  }: renderProfileFieldsType) => (
    <div className="flex items-center justify-between w-60">
      <h3 className="flex text-sm font-semibold text-gray-900">
        {label}
        {mandatoryField && <span className="text-red-600 pl-1">*</span>}
        <InformationCircleIcon
          data-tooltip-id={tooltipId}
          className="w-6 h-6 ml-2"
        />
      </h3>
      <button
        type="button"
        className={`rounded-full text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange ${
          showEdit ? "text-red-500" : "text-entntblue hover:text-entntorange"
        }`}
        onClick={handleClick}
      >
        {!showEdit ? (
          <PlusCircleIcon
            height={44}
            onClick={() => {
              setTimeout(() => {
                !validatePersonalInfo &&
                  window.scrollTo(0, document.body.scrollHeight);
              }, 0);
            }}
          />
        ) : (
          <MinusCircleIcon
            height={44}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
        )}
      </button>
    </div>
  );

  useEffect(() => {
    if (validatePersonalInfo) {
      setshowEduEdit(false);
      setShowExpEdit(false);
    }
  }, [validatePersonalInfo]);

  const handleEducation = () => {
    if (validatePersonalInfo) {
      setError("Please provide your personal information.");
      return;
    } else {
      setError("");
      setshowEduEdit(!showEduEdit);
      setShowExpEdit(false);
      clearEduStates();
    }
  };
  const handleExperience = () => {
    if (validatePersonalInfo) {
      setError("Please provide your personal information.");
      return;
    } else {
      setError("");
      setShowExpEdit(!showExpEdit);
      setshowEduEdit(false);
      clearExpStates();
    }
  };

  return (
    <>
      <h1 className="text-base font-semibold text-gray-900">Profile</h1>
      <div className="w-full flex flex-col items-start justify-center">
        <div className="py-2 rounded-md space-y-2">
          {!showExpEdit &&
            renderProfileFields({
              label: "Education",
              handleClick: () => handleEducation(),
              showEdit: showEduEdit,
              tooltipId: "education-tooltip",
              mandatoryField: true,
            })}

          {!showEduEdit &&
            renderProfileFields({
              label: "Work Experience",
              handleClick: () => handleExperience(),
              showEdit: showExpEdit,
              tooltipId: "workexperience-tooltip",
            })}
        </div>
        {error && validatePersonalInfo && (
          <p className=" whitespace-nowrap text-xs text-red-500">{error}</p>
        )}
      </div>
      {tooltipConfigs.map((tooltip) => (
        <ReactTooltip
          key={tooltip.id}
          id={tooltip.id}
          content={tooltip.content}
        />
      ))}
    </>
  );
};
