import { useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { dropDownValueType } from "../../Models";

type hyperLinkContent = {
  label: string;
  action: () => void;
};

type apiRequestContent = {
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  searchTerm?: string;
  loading?: boolean;
};

type SearchDropDownType = {
  label?: string;
  placeHolder: string;
  dropDownValues: dropDownValueType[];
  state: dropDownValueType | undefined;
  setState: React.Dispatch<React.SetStateAction<dropDownValueType | undefined>>;
  mandatoryField?: boolean;
  dropDownOnly?: boolean;
  hideOthers?: boolean;
  disable?: boolean;
  hyperLink?: hyperLinkContent;
  apiRequest?: apiRequestContent;
  optionsClassName?: string;
  error?: string;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchDropDown({
  label,
  placeHolder,
  dropDownValues,
  state,
  setState,
  mandatoryField,
  dropDownOnly,
  hideOthers,
  disable = false,
  hyperLink,
  apiRequest,
  optionsClassName,
  error,
}: SearchDropDownType) {
  const [query, setQuery] = useState("");
  const [localError, setLocalError] = useState<string | null>(error || null);
  const [touched, setTouched] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showNoResults, setShowNoResults] = useState<boolean>(false);

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const target = event.target as HTMLUListElement;
    if (
      Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <=
      1
    ) {
      if (apiRequest?.setPageNumber) {
        apiRequest.setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  const filteredDropDownValues = [
    ...dropDownValues?.filter((value: dropDownValueType) => {
      return value?.name.toLowerCase().startsWith(query.toLowerCase());
    }),
    ...(query === "" && !hideOthers ? [{ id: "0", name: "Other" }] : []),
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!dropDownOnly && apiRequest?.setSearchTerm) {
      apiRequest?.setSearchTerm(e.target.value);
    } else if (!dropDownOnly) {
      setQuery(e.target.value);
    }
  };

  const handleBlur = () => {
    setTouched(true);
    setIsOpen(false);
  };

  useEffect(() => {
    let delayTimer: NodeJS.Timeout;

    if (
      !apiRequest?.loading &&
      isOpen &&
      ((apiRequest?.searchTerm && apiRequest?.searchTerm.length >= 3) ||
        query.length > 0)
    ) {
      delayTimer = setTimeout(() => {
        setShowNoResults(true);
      }, 500);
    } else {
      setShowNoResults(false);
    }

    return () => clearTimeout(delayTimer);
  }, [apiRequest?.loading, isOpen, apiRequest?.searchTerm, query]);

  useEffect(() => {
    setLocalError(error ?? "");
  }, [error]);
  return (
    <Combobox
      as="div"
      value={state}
      onChange={(value) => {
        setState(value);
        setLocalError(value.name.trim() && null);
      }}
      onBlur={handleBlur}
      className="w-full"
      disabled={disable || false}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
        {mandatoryField && <span className="text-red-600 pl-1">*</span>}
        {hyperLink?.label && (
          <span
            onClick={hyperLink.action}
            className="inline-block sm:inline cursor-pointer sm:pl-2 text-[10px] underline text-entntblue"
          >
            {hyperLink.label}
          </span>
        )}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Button className="w-full relative flex items-center rounded-md focus:outline-none">
          <Combobox.Input
            className={`w-full !text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
              !state?.name && touched && localError && "ring-red-500"
            }`}
            onChange={(event) => handleInputChange(event)}
            displayValue={(value: dropDownValueType) => value?.name}
            placeholder={placeHolder}
            onClick={() => {
              setQuery("");
              apiRequest?.setSearchTerm && apiRequest?.setSearchTerm("");
              setIsOpen(true);
            }}
          />
          <ChevronUpDownIcon
            className="absolute right-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {apiRequest?.loading ? (
          <div className="absolute z-10 mt-1 w-full text-center text-gray-500">
            <div className="flex flex-row gap-2 justify-center">
              <div className="w-1.5 h-1.5 rounded-full bg-entntorange animate-bounce"></div>
              <div className="w-1.5 h-1.5 rounded-full bg-entntorange animate-bounce [animation-delay:-.3s]"></div>
              <div className="w-1.5 h-1.5 rounded-full bg-entntorange animate-bounce [animation-delay:-.5s]"></div>
            </div>
          </div>
        ) : filteredDropDownValues.length > 0 ? (
          <Combobox.Options
            onScroll={(e) => handleScroll(e)}
            className={classNames(
              "absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
              optionsClassName ?? "",
            )}
          >
            {filteredDropDownValues.map((value) => (
              <Combobox.Option
                key={value.id}
                value={value}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900",
                  )
                }
                title={value.name}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block",
                        selected ? "font-semibold" : "",
                      )}
                    >
                      {value.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600",
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        ) : (
          showNoResults && (
            <div className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm text-center text-gray-500 cursor-default select-none">
              No results found
            </div>
          )
        )}
        {!state?.name && touched && localError && (
          <p className="absolute text-xs text-red-500">{localError}</p>
        )}
      </div>
    </Combobox>
  );
}
