import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import RecruitmentTimeline from "./RecruitmentTimeline";
import { ArrowDownTrayIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import { RoleStatus, Role, Assignment } from "../../../../Models";
import { GetAssignment, GetCandidateScore } from "../../../../WebCalls";
import useIdToken from "../../../../hooks/useIdToken";
import RoleInfo from "../RoleInfo";
import { Link } from "react-router-dom";

type props = {
  timelineData: any[];
  role: Role;
  status: RoleStatus[];
  candidateStatus: any[];
  candidate: any;
};
interface TimelineEvent {
  [key: string]: {
    date: string;
    color: string;
    radius: number;
    text: string;
    id: string;
    dependsOn: string;
  };
}

export default function RecruitmentTimelineModal(props: props) {
  const [timelineStatus, setTimelineStatus] = useState<string[]>([]);
  const [timelineData, setTimelineData] = useState<TimelineEvent[]>([]);
  const [expandData, setExpandData] = useState<string>("");
  const [candidateScore, setCandidateScore] = useState<number>();
  const [assignment, setAssignment] = useState<Assignment>();

  const idToken = useIdToken();

  useEffect(() => {
    const statusDetails =
      props.status &&
      props.status.map((s) => {
        return { name: s.name, order: s.order };
      });

    statusDetails.sort((a, b) => a.order - b.order);
    statusDetails.unshift({ name: "Job Posted", order: 0 });
    const sortedStatuses = statusDetails.map((s) => s.name);
    setTimelineStatus(sortedStatuses);
  }, [props.status]);

  useEffect(() => {
    if (!idToken) return;
    GetCandidateScore(idToken.candidateId, idToken.token).then((res) => {
      const score = res.find((r) => r.roleId === props.role.id);
      setCandidateScore(score?.score);
    });

    GetAssignment(idToken.candidateId, props.role.id).then((res) => {
      setAssignment(res);
    });
  }, [idToken, props.role]);

  useEffect(() => {
    initializeTimelineData();
    setExpandData("");
  }, [
    timelineStatus,
    props.candidateStatus,
    props.candidate,
    assignment,
    candidateScore,
  ]);

  const initializeTimelineData = async () => {
    if (
      timelineStatus.length > 0 &&
      props.candidateStatus &&
      props.candidateStatus.length > 0
    ) {
      const updatedTimelineData: TimelineEvent[] = props.candidateStatus
        .map((event: any) => {
          let color = "gray";
          if (event.name.toLowerCase() === "assessment") {
            color = "blue";
            const assessmentDate = new Date(event.date);
            const applicationReceivedDate = new Date(assessmentDate.getTime());
            const assessmentLinkSentDate = new Date(
              assessmentDate.getTime() + 60000,
            );

            const ApplicationReceived: TimelineEvent = {
              "Application Received": {
                date: applicationReceivedDate.toISOString(),
                color: "orange",
                radius: 50,
                text: "Application Received",
                id: "Application Received",
                dependsOn: event.name,
              },
            };

            const AssessmentLinkSent: TimelineEvent = {
              "Assessment Link Sent": {
                date: assessmentLinkSentDate.toISOString(),
                color: "rgb(0, 153, 255)",
                radius: 50,
                text: "Assessment Link Sent",
                id: "Assessment Link Sent",
                dependsOn: event.name,
              },
            };
            const hasInterview = props.candidateStatus.some(
              (status) =>
                status.name === "Interview" ||
                status.name === "Technical Interview",
            );
            if (candidateScore && !hasInterview) {
              let AssessmentDone: TimelineEvent = {
                "Assessment Done": {
                  date: new Date(
                    assessmentLinkSentDate.getTime() + 60000,
                  ).toISOString(),
                  color: "orange",
                  radius: 50,
                  text: `Score - ${candidateScore}`,
                  id: "Assessment Done",
                  dependsOn: event.name,
                },
              };
              return [ApplicationReceived, AssessmentLinkSent, AssessmentDone];
            }

            return [ApplicationReceived, AssessmentLinkSent];
          } else if (
            event.name.toLowerCase() === "interview" ||
            event.name.toLowerCase() === "technical interview"
          ) {
            const interviewDate = new Date(event.date);
            const interviewLinkSentDate = new Date(
              interviewDate.getTime() + 60000,
            );

            const InterviewLinkSent: TimelineEvent = {
              "Interview Link Sent": {
                date: interviewLinkSentDate.toISOString(),
                color: "rgb(0, 153, 255)",
                radius: 50,
                text: "Interview Link Sent",
                id: "Interview Link Sent",
                dependsOn: event.name,
              },
            };
            let AssessmentDone: TimelineEvent = {};
            if (candidateScore) {
              AssessmentDone = {
                "Assessment Done": {
                  date: interviewDate.toISOString(),
                  color: "orange",
                  radius: 50,
                  text: `Score - ${candidateScore}`,
                  id: "Assessment Done",
                  dependsOn: event.name,
                },
              };

              return [AssessmentDone, InterviewLinkSent];
            }

            return [InterviewLinkSent];
          } else if (event.name.toLowerCase() === "assignment") {
            const interviewDoneDate = new Date(event.date);
            const assignmentLinkSent = new Date(
              interviewDoneDate.getTime() + 60000,
            );

            const TechnicalInterviewDone: TimelineEvent = {
              "Interview Done": {
                date: event.date,
                color: "brown",
                radius: 50,
                text: "Interview Done",
                id: "Interview Done",
                dependsOn: event.name,
              },
            };

            const AssignmentLinkSent: TimelineEvent = {
              "Assignment Link Sent": {
                date: assignmentLinkSent.toISOString(),
                color: "rgb(0, 153, 255)",
                radius: 50,
                text: "Assignment Link Sent",
                id: "Assignment Link Sent",
                dependsOn: event.name,
              },
            };

            if (assignment) {
              const AssignmentSubmitted: TimelineEvent = {
                "Assignment Submitted": {
                  date: assignment.submissionDate,
                  color: "orange",
                  radius: 50,
                  text: "Assignment Submitted",
                  id: "Assignment Submitted",
                  dependsOn: event.name,
                },
              };

              return [
                TechnicalInterviewDone,
                AssignmentLinkSent,
                AssignmentSubmitted,
              ];
            }

            return [TechnicalInterviewDone, AssignmentLinkSent];
          } else if (event.name.toLowerCase() === "offered") {
            const offeredDate = new Date(event.date);

            const finalInterviewDoneDate = new Date(offeredDate.getTime());

            const FinalInterviewDone: TimelineEvent = {
              "Final Interview Done": {
                date: finalInterviewDoneDate.toISOString(),
                color: "brown",
                radius: 50,
                text: "Final Interview Done",
                id: "Final Interview Done",
                dependsOn: event.name,
              },
            };
            const Offered: TimelineEvent = {
              Offered: {
                date: offeredDate.toISOString(),
                color: "blue",
                radius: 50,
                text: "Offered",
                id: "Offered",
                dependsOn: event.name,
              },
            };

            return [FinalInterviewDone, Offered];
          } else if (event.name.toLowerCase() === "disqualified") {
            const disqualifiedDate = new Date(event.date);
            const interviewDoneDate = new Date(disqualifiedDate.getTime());

            const Disqualified: TimelineEvent = {
              Disqualified: {
                date: disqualifiedDate.toISOString(),
                color: "red",
                radius: 50,
                text: "Disqualified",
                id: "Disqualified",
                dependsOn: event.name,
              },
            };
            const hasFinalInterview = props.candidateStatus.some(
              (status) =>
                status.name === "Final Interview" ||
                status.name === "Final Interview",
            );
            if (hasFinalInterview) {
              const FinalInterviewDone: TimelineEvent = {
                "Final Interview Done": {
                  date: interviewDoneDate.toISOString(),
                  color: "brown",
                  radius: 50,
                  text: "Final Interview Done",
                  id: "Final Interview Done",
                  dependsOn: event.name,
                },
              };

              return [FinalInterviewDone, Disqualified];
            }
            return [Disqualified];
          } else {
            color =
              timelineStatus.includes(event.name) ||
              event.name === "roleCreated"
                ? "rgb(0, 153, 255)"
                : "gray";
            return {
              [event.name]: {
                date: event.date,
                color: event.name === "Disqualified" ? "red" : color,
                radius: 50,
                text:
                  event.name.toLowerCase() === "final interview"
                    ? "Final Interview Link Sent"
                    : event.name,
                id: event.name,
                dependsOn: event.name,
              },
            };
          }
        })
        .flat();
      setTimelineData(updatedTimelineData);
    }
  };

  const getExpandedData = (id: string) => {
    setExpandData(id);
  };

  return (
    <div className="bg-white rounded-2xl h-full w-full overflow-y-scroll md:overflow-hidden">
      <div className="z-10 sticky top-0  m-1 sm:flex sm:items-start bg-white dark:bg-darkbglight px-9 py-4 border-b border-gray-200">
        <div className="items-center font-medium w-full flex justify-center space-x-2">
          <Link to={`/role/${props.role.id}/overview`} className="hover:text-entntblue">{props.role?.title}</Link>
          <RoleInfo role={props.role} />
        </div>
      </div>
      <div className="h-full p-8 flex items-center justify-center">
        <RecruitmentTimeline
          timelineData={timelineData}
          timelineStatus={timelineStatus}
          getExpandedData={getExpandedData}
        />
      </div>
      {expandData && (
        <div className="h-auto px-14 py-4 border-2 mt-2">
          {expandData === "Assessment Done" && (
            <div className="text-left text-sm">
              <div className="">Candidate Grade : {candidateScore}</div>
              <div className="mt-2">
                Candidate Performance : Top {candidateScore}
              </div>
            </div>
          )}
          {expandData === "Assignment Submitted" && (
            <div className="text-left text-sm flex flex-row">
              <div className="flex flex-col justify-between">
                <div>
                  <div className="sm:min-w-56 mt-1">
                    Assignment Submission Link -{" "}
                    <a
                      href={`${assignment?.assignmentLink}`}
                      className="text-blue-400"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Link
                    </a>
                  </div>
                </div>
              </div>
              {assignment?.comments && assignment?.comments.length > 0 && (
                <div className="flex-grow bg-gray-200 ml-6 px-2 py-1 h-auto rounded-lg text-gray-700">
                  {assignment?.comments}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
