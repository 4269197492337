import { useEffect } from "react";

const useDebounce = (
  callback: () => void,
  dependencies: any[],
  delay: number,
) => {
  useEffect(() => {
    const timer = setTimeout(callback, delay);
    return () => clearTimeout(timer);
  }, [...dependencies, delay]);
};

export default useDebounce;
