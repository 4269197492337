const navigation = [
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/entnt",
    icon: (props: any) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];

export default function Example() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-black-400">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="flex justify-center space-x-6 md:order-1">
          <a
            href="https://www.entnt.in/privacy-policy"
            className="text-xs leading-6 text-entntdarkblue hover:text-gray-900"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.entnt.in/terms-and-conditions"
            className="text-xs leading-6 text-entntdarkblue hover:text-gray-900"
          >
            Terms and Conditions
          </a>
        </div>
        <div className="mt-8 md:order-3 md:mt-0">
          <p className="text-center text-xs leading-5 text-entntdarkblue">
            Copyright © {new Date().getFullYear()} ENTNT | All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
}
