import React, { useState, useEffect } from "react";
import {
  BriefcaseIcon,
  CurrencyDollarIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";

import { GetRole, GetRoles } from "../../../WebCalls";
import { Role } from "../../../Models";
import { NotFound } from "../../NotFound";
import { Link } from "react-router-dom";
import ConfirmModal from "../../shared/ConfirmModal";
import ApplyToRole from "./ApplyToRole";
import useCandidateProfile from "../../../hooks/useCandidateProfile";
import { Spinner } from "../../shared/Spinner";

type RoleParams = {
  id: string;
};

export default function RoleOverview() {
  const [role, setRole] = useState<Role>();
  const [applyModal, setApplyModal] = useState(false);
  const [openDeclaration, setOpenDeclaration] = useState(false);
  const [roleIds, setRoleIds] = useState<string[]>();
  const [checkRoute, setCheckRoute] = useState<boolean>(true);
  const candidate = useCandidateProfile();

  const { id } = useParams<RoleParams>();
  const navigate = useNavigate();

  useEffect(() => {
    GetRoles()
      .then((data) =>
        setRoleIds(data.filter((r) => r.isActive).map((r) => r.id)),
      )
      .finally(() => {
        setCheckRoute(false);
      });
  }, []);

  if (!checkRoute && id && !roleIds?.includes(id)) {
    navigate("/notfound");
  }

  useEffect(() => {
    if (id === undefined) return;
    GetRole(id).then((rl) => setRole(rl));
  }, [id]);

  if (role && id !== role?.id) {
    return <NotFound />;
  }

  const jD = parse(role?.description || "");

  if (checkRoute) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto">
      <>
        <div className="mx-12 my-4 lg:flex lg:items-center lg:justify-between border-b border-gray-900/10 pb-4">
          <div className="min-w-0 flex-1">
            <h2 className="text-xl font-bold leading-7 font-display text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {role?.title}
            </h2>
            <div className="flex flex-row flex-wrap">
              <div className="mt-2 pr-2 flex items-center text-xs sm:text-sm text-gray-500">
                <BriefcaseIcon
                  className="mr-1.5 h-3 sm:h-5 w-3 sm:w-5 flex-shrink-0 text-entntblue"
                  aria-hidden="true"
                />
                {role?.employmentType}
              </div>
              <div className="mt-2 pr-2 flex items-center text-xs sm:text-sm text-gray-500">
                <MapPinIcon
                  className="mr-1.5 h-3 sm:h-5 w-3 sm:w-5 flex-shrink-0 text-entntblue"
                  aria-hidden="true"
                />
                {role?.location}
              </div>
              <div className="mt-2 flex items-center text-xs sm:text-sm text-gray-500">
                <CurrencyDollarIcon
                  className="mr-1.5 h-3 sm:h-5 w-3 sm:w-5 flex-shrink-0 text-entntblue"
                  aria-hidden="true"
                />
                {role?.salary}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500"></div>
            </div>
          </div>
        </div>
      </>
      <div>
        <div className="job-description bg-white px-12 leading-7">
          <div className="my-8">{jD}</div>
        </div>
        {candidate ? (
          <button
            className="mx-12 rounded-md bg-entntblue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange"
            disabled={!role || !candidate}
            onClick={() => {
              setApplyModal(true);
            }}
          >
            Apply
          </button>
        ) : (
          <Link
            to={`/role/${role?.id}/application`}
            className="mx-12 rounded-md bg-entntblue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange"
          >
            Apply
          </Link>
        )}
      </div>
      <ConfirmModal
        type={"info"}
        open={applyModal}
        setOpen={setApplyModal}
        title={"Apply for Role"}
        description={
          <div>
            Are you sure you would like to apply for this role? If you need to
            update your profile, please visit the{" "}
            <Link to={"/candidate/profile"} className="text-entntblue">
              profile page
            </Link>{" "}
            before proceeding.
          </div>
        }
        buttonContents={[
          {
            buttonId: 1,
            buttonLabel: "Confirm",
            buttonAction: () => {
              setOpenDeclaration(true);
            },
          },
          {
            buttonId: 2,
            buttonLabel: "Cancel",
            buttonAction: () => {
              setApplyModal(false);
            },
            isOutline: true,
          },
        ]}
      />

      {candidate && role && (
        <ApplyToRole
          openDeclaration={openDeclaration}
          setOpenDeclaration={setOpenDeclaration}
          candidate={candidate}
          roleId={role.id}
        />
      )}
    </div>
  );
}
