import React, { useEffect, useRef } from "react";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { addDays, addMonths } from "date-fns";
import ReactDatePicker from "react-datepicker";

type CareerGapType = {
  gaps: [
    {
      gapEntry: { totalMonths: number; gap: boolean };
      previousEntry: any;
      nextEntry: any;
    },
  ];
  handleEduExpChange: (
    entryType: "experience" | "education",
    field: "startDate" | "endDate",
    value: Date | null,
    id: string,
  ) => void;
  isGapCorrect: boolean;
};

export const CareerGap = ({
  gaps,
  handleEduExpChange,
  isGapCorrect,
}: CareerGapType) => {
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (datePickerRef.current) {
      const inputs = datePickerRef.current.querySelectorAll("input");
      inputs.forEach((input) => {
        input.setAttribute("tabindex", "-1");
      });
    }
  }, []);

  return (
    <div
      ref={datePickerRef}
      className={`space-y-2 pt-2 ${
        isGapCorrect && "opacity-[0.8] pointer-events-none select-none"
      }`}
    >
      {gaps.map((gap) => (
        <div key={gap.previousEntry?.id} className="bg-gray-100 rounded-md p-2">
          <div className="py-1">
            There is a Gap of {gap.gapEntry.totalMonths} months between &nbsp;
            <span
              style={{
                color: gap.previousEntry.company
                  ? "#0D42EC"
                  : gap.previousEntry.postGrad
                  ? "#e1ad01"
                  : "#F88201",
              }}
            >
              {gap.previousEntry?.university
                ? gap.previousEntry?.university.name
                : gap.previousEntry?.company.name}
            </span>
            &nbsp; and &nbsp;
            <span
              style={{
                color: gap.nextEntry.company
                  ? "#0D42EC"
                  : gap.nextEntry.postGrad
                  ? "#e1ad01"
                  : "#F88201",
              }}
            >
              {gap.nextEntry?.university
                ? gap.nextEntry?.university.name
                : gap.nextEntry?.company.name}
            </span>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-end gap-4">
            <div className="text-xs sm:w-1/2 font-medium leading-6 space-y-2">
              <label>
                End Date of &nbsp;
                {gap.previousEntry?.university
                  ? gap.previousEntry?.university.name
                  : gap.previousEntry?.company.name}
              </label>
              <ReactDatePicker
                id={`end-date-${gap.previousEntry?.university?.id}`}
                className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                selected={gap.previousEntry?.endDate}
                onChange={(date) =>
                  handleEduExpChange(
                    gap!.previousEntry?.university ? "education" : "experience",
                    "endDate",
                    date && addDays(date, 1),
                    gap!.previousEntry?.id,
                  )
                }
                dateFormat="MM/yyyy"
                showMonthYearPicker
                closeOnScroll
                shouldCloseOnSelect={true}
                minDate={addMonths(gap.previousEntry.startDate, 1)}
                maxDate={gap.nextEntry.startDate}
                showIcon
                icon={
                  <CalendarDaysIcon className="text-gray-400 absolute bottom-1" />
                }
                calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                onFocus={(e) => {
                  if (/iPhone|iPad/i.test(navigator.userAgent)) {
                    e.target.readOnly = true;
                  }
                }}
                disabled={isGapCorrect}
              />
            </div>
            <div className="text-xs sm:w-1/2 font-medium leading-6 space-y-2">
              <label>
                Start Date of &nbsp;
                {gap.nextEntry?.university
                  ? gap.nextEntry?.university.name
                  : gap.nextEntry?.company.name}
              </label>
              <ReactDatePicker
                id={`start-date-${gap.previousEntry?.university?.id}`}
                className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                selected={gap.nextEntry?.startDate}
                onChange={(date) =>
                  handleEduExpChange(
                    gap!.nextEntry?.university ? "education" : "experience",
                    "startDate",
                    date && addDays(date, 1),
                    gap!.nextEntry?.id,
                  )
                }
                dateFormat="MM/yyyy"
                showMonthYearPicker
                closeOnScroll
                shouldCloseOnSelect={true}
                minDate={addMonths(gap.previousEntry.endDate, 1)}
                maxDate={new Date()}
                showIcon
                icon={
                  <CalendarDaysIcon className="text-gray-400 absolute bottom-1" />
                }
                calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                onFocus={(e) => {
                  if (/iPhone|iPad/i.test(navigator.userAgent)) {
                    e.target.readOnly = true;
                  }
                }}
                disabled={isGapCorrect}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
