import { TrashIcon } from "@heroicons/react/24/solid";

import { PreviewEducation, PreviewExperience } from "../../../Models";
import { PencilIcon } from "@heroicons/react/20/solid";

type CareerOverviewTypes = {
  previewCareer: (PreviewEducation | PreviewExperience)[];
  onRemoveCareer: (career: PreviewEducation | PreviewExperience) => void;
  onEditCareer: (career: PreviewEducation | PreviewExperience) => void;
};

export const CareerOverview = ({
  previewCareer,
  onRemoveCareer,
  onEditCareer,
}: CareerOverviewTypes) => {
  const sortedPreview = previewCareer.sort((a, b) => {
    if (a.startDate && b.startDate) {
      return b.startDate.getTime() - a.startDate.getTime();
    }
    return 0;
  });

  const careerOverviewHeader = [
    {
      id: 1,
      label: "Institution",
    },
    {
      id: 2,
      label: "Period",
    },
    {
      id: 3,
      label: "Role",
    },
    {
      id: 4,
      label: "Relevant to Job",
    },
  ];

  return (
    <div>
      {previewCareer.length > 0 && (
        <div className="">
          <div className="py-2 border-b border-gray-300 grid grid-cols-7 sm:grid-cols-4 gap-y-3 gap-x-2">
            {careerOverviewHeader.map((field) => (
              <div
                key={field.id}
                className={`text-xs font-semibold text-gray-900 col-span-2 sm:col-span-1 ${
                  field.id === 4 ? "hidden sm:block" : ""
                }`}
              >
                {field.label}
              </div>
            ))}
          </div>
          <div
            className={`${
              sortedPreview.length >= 4 ? "h-20 overflow-y-scroll" : "h-full"
            }`}
          >
            <div className="">
              {sortedPreview.map((pre: any) => (
                <div
                  key={pre.id}
                  className="relative py-1 border-b border-gray-300 grid grid-cols-7 sm:grid-cols-4 gap-y-3 gap-x-2"
                >
                  <div
                    title={
                      pre.universityName
                        ? pre.universityName.name
                        : pre.companyName.name
                    }
                    className="col-span-2 sm:col-span-1 text-xs text-gray-900 "
                  >
                    {pre.universityName
                      ? pre.universityName.name
                      : pre.companyName.name}
                  </div>
                  <div className="col-span-2 sm:col-span-1 text-xs text-gray-900">
                    {pre.period}
                  </div>
                  <div className="col-span-2 sm:col-span-1 text-xs text-gray-900">
                    {pre.title ? pre.title : pre.degreeType ? "PG" : "UG"}
                  </div>
                  <div className="hidden sm:block col-span-1 text-xs text-gray-900">
                    {pre.isRelevant ? "Yes" : "No"}
                  </div>

                  <div className="col-span-1 sm:absolute sm:space-x-2 right-0 sm:right-2 top-0">
                    <button
                      type="button"
                      onClick={() => onEditCareer(pre)}
                      className="inline-flex items-center bg-transparent px-2 py-1 text-sm font-semibold text-entntblue shadow-md rounded-md ring-1 ring-inset ring-gray-300 hover:text-entntorange"
                      title="Edit"
                    >
                      <PencilIcon
                        className="h-3 w-3"
                        onClick={() => {
                          setTimeout(() => {
                            window.scrollTo(0, document.body.scrollHeight);
                          }, 0);
                        }}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => onRemoveCareer(pre)}
                      className="inline-flex items-center bg-transparent px-2 py-1 text-sm font-semibold text-red-500 shadow-md rounded-md ring-1 ring-inset ring-gray-300"
                      title="Delete"
                    >
                      <TrashIcon
                        className="h-3 w-3"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
