import React from "react";
import useIdToken from "../../hooks/useIdToken";

type ActionButtonsType = {
  currentStep: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  onApply?: () => void;
  disable?: boolean;
};
const ActionButtons = ({
  currentStep,
  goToNextStep,
  goToPreviousStep,
  onApply,
  disable = false,
}: ActionButtonsType) => {
  const idToken = useIdToken();
  return (
    <div className="lg:hidden pt-6 w-full flex justify-between px-10">
      <button
        type="button"
        className={`block rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange ${
          currentStep <= 1 ? "bg-gray-400" : "bg-entntblue"
        }`}
        onClick={() => {
          goToPreviousStep();
          window.scrollTo(0, 0);
        }}
        disabled={currentStep <= 1}
      >
        Previous
      </button>

      {currentStep >= 3 ? (
        <button
          type="button"
          className="rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange"
          onClick={onApply}
        >
          {idToken ? "Save" : "Apply"}
        </button>
      ) : (
        <button
          type="button"
          className={`block rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange ${
            currentStep >= 3 || disable
              ? "bg-gray-400"
              : "bg-entntblue hover:bg-entntorange"
          }`}
          onClick={() => {
            goToNextStep();
            window.scrollTo(0, 0);
          }}
          disabled={currentStep >= 3 || disable}
        >
          Next
        </button>
      )}
    </div>
  );
};

export default ActionButtons;
