import React from "react";

type RadioButtonTypes = {
  id: string;
  label: string;
  value: string;
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
};

export const RadioButtons = ({
  id,
  label,
  value,
  selectedValue,
  setSelectedValue,
}: RadioButtonTypes) => {
  const handleChange = () => {
    setSelectedValue(value);
  };
  return (
    <div>
      <div className="flex flex-col space-y-4 py-1 px-5">
        <label
          htmlFor={id}
          className="relative flex items-center cursor-pointer"
        >
          <input
            id={id}
            value={value}
            checked={selectedValue === value}
            className="sr-only peer"
            name="futuristic-radio"
            type="radio"
            onChange={handleChange}
          />
          <div className="w-4 h-4 bg-transparent border-2 border-entntblue-500 rounded-full peer-checked:bg-entntblue peer-checked:border-entntblue peer-hover:shadow-sm peer-hover:shadow-gray-500/50 peer-checked:shadow-sm peer-checked:shadow-gray-500/50 transition duration-300 ease-in-out"></div>
          <span className="ml-2">{label}</span>
        </label>
      </div>
    </div>
  );
};
