import { useState } from "react";
import { InputFieldType } from "../../Models";

export const InputField = ({
  label,
  inputId,
  autoComplete,
  placeHolder,
  setValue = () => {},
  value,
  mandatoryField,
  isDisabled,
  error,
  onKeyDown = () => {},
  validate = () => null,
}: InputFieldType) => {
  const [localError, setLocalError] = useState<string | null>(error || null);
  const [touched, setTouched] = useState<boolean>(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newValue = e.target.value;
    setValue(newValue);

    const validationError = validate(newValue);
    setLocalError(validationError);
  };

  return (
    <div className="w-full">
      <label
        htmlFor={inputId}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label} {mandatoryField && <span className="text-red-600">*</span>}
      </label>
      <div className="mt-2 w-full text-sm">
        {label === "Description" ? (
          <textarea
            rows={4}
            name={inputId}
            id={inputId}
            onChange={(event) => setValue(event.target.value)}
            className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
          />
        ) : (
          <input
            type="text"
            name={inputId}
            id={inputId}
            autoComplete={autoComplete}
            value={value}
            placeholder={placeHolder}
            onChange={handleChange}
            className={`block text-sm font-medium w-full rounded-md border-0 py-1.5 sm:py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6 ${
              !value && touched && localError && "ring-red-500"
            }`}
            disabled={isDisabled}
            onKeyDown={onKeyDown}
            onBlur={() => setTouched(true)}
          />
        )}
        {!value && touched && (localError || error) && (
          <p className="absolute text-xs text-red-500">
            {value?.trim() === "" ? error : localError}
          </p>
        )}
      </div>
    </div>
  );
};
