import React from "react";

export const NotFound = () => {
  return (
    <div className="h-[65vh] px-12 flex items-center justify-center">
      <div className="max-w-xl text-base text-center md:text-xl flex flex-col items-center space-y-4">
        <h1>
          Oops! Looks like you've reached a dead end. The page you're trying to
          find isn't here. Let's head back to where we started.
        </h1>
        <a href="/">
          <button
            className="w-fit rounded-md bg-entntblue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange"
            type="button"
          >
            Back Home
          </button>
        </a>
      </div>
    </div>
  );
};
