import {
  BriefcaseIcon,
  CheckCircleIcon,
  EyeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

type StepperType = {
  currentStep: number;
};

const Stepper = ({ currentStep }: StepperType) => {
  const steps = [
    {
      step: "Personal Info",
      icon: <UserIcon className="h-3 w-3 rounded-full" />,
    },
    {
      step: "Career Info",
      icon: <BriefcaseIcon className="h-3 w-3 rounded-full" />,
    },
    {
      step: "Preview",
      icon: <EyeIcon className="h-3 w-3 rounded-full" />,
    },
  ];
  return (
    <div className="flex justify-between w-full">
      {steps?.map((step, i) => (
        <div
          key={i}
          className={`relative flex flex-col justify-center items-center w-full`}
        >
          <div
            className={`w-5 h-5 flex items-center justify-center z-10 relative rounded-full font-medium text-white ${
              i + 1 < currentStep
                ? "bg-entntorange"
                : currentStep === i + 1
                ? "bg-entntblue transition-colors delay-200"
                : "bg-gray-300 text-gray-800"
            }`}
          >
            {i + 1 < currentStep ? <CheckCircleIcon /> : step.icon}
          </div>
          <p className="text-[10px] sm:text-xs text-gray-500">{step.step}</p>
          {i !== 0 && (
            <>
              <div
                className={`absolute w-[80%] h-[2px] -left-[40%] top-1/4 bg-gray-300`}
              />
              <div
                className={`absolute h-[2px] -left-[40%] top-1/4 ${
                  i + 1 < currentStep || currentStep === i + 1
                    ? "bg-entntorange animate-slide-right"
                    : currentStep !== i + 1
                    ? "bg-entntorange animate-slide-left"
                    : "bg-entntorange"
                }`}
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
