import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  Cog6ToothIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import entntlogo from "../img/entntlogo.svg";
import useCandidateProfile from "../hooks/useCandidateProfile";

export default function Header() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const candidate = useCandidateProfile();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const navigation = [
    { name: "Careers Home", href: "/" },
    { name: "About us", href: "/#about" },
    { name: "Leadership", href: "/#leadership" },
    { name: "Roles", href: "/#roles" },
    !candidate
      ? { name: "Login", href: "/candidate/login" }
      : { name: "Applications", href: "/candidate" },
    ...(candidate && mobileMenuOpen ? [{ name: "Active Roles", href: "candidate/active-roles" }, { name: "Profile", href: "candidate/profile" }, { name: "Logout", href: "/logout" },] : []),
  ];

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    localStorage.removeItem("idToken");
    window.location.href = "/";
  };

  return (
    <header className="bg-white  shadow-md">
      <nav
        className="flex items-center justify-between p-2 lg:px-8 lg:max-w-7xl lg:mx-auto"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="https://www.entnt.in" className="-m-1.5 p-1.5">
            <img className="h-10 w-auto" src={entntlogo} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-entntblue"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Fragment key={item.name}>
              {item.name !== "Logout" && <Link
                key={item.name}
                to={item.href}
                className="text-sm font-semibold leading-6 text-entntblue"
              >

                {item.name}
              </Link>}
            </Fragment>
          ))}

          {candidate && (
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="font-semibold leading-6 text-entntblue"
              >
                <Cog6ToothIcon className="h-6" />
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 min-w-[12rem] bg-white shadow-lg rounded-lg py-1 z-10 border-gray-200 border">
                  <span className="block py-2 px-4 text-sm">
                    {candidate?.email}
                  </span>
                  <a
                    href="/"
                    onClick={handleLogout}
                    className="block px-4 py-2 text-sm text-entntblue  hover:bg-entntorange hover:text-white border-t"
                  >
                    Logout
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="https://www.entnt.in" className="-m-1.5 p-1.5">
              <img className="h-20 w-auto" src={entntlogo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-entntblue"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-entntblue">
              <div className="space-y-2 py-6">
                {navigation.map((item) =>
                  item.name === "Logout" ? (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={(e) => {
                        setMobileMenuOpen(false);
                        handleLogout(e);
                      }}
                      className="-mx-3 block rounded-lg px-3 py-2 font-semibold leading-7 text-entntblue hover:bg-gray-50"
                    >
                      {"Logout"}
                    </a>
                  ) : (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 font-semibold leading-7 text-entntblue hover:bg-gray-50"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </Link>
                  ),
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
