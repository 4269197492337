import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { City, dropDownValueType } from "../../../Models";
import NearestLocation from "./NearestLocation";
import ReactTooltip from "../../shared/ReactTooltip";

type WorkLocationType = {
  states: dropDownValueType[];
  cities: City[];
  workLocations: City[];
  setWorkLocations: React.Dispatch<React.SetStateAction<City[]>>;
  showSubcityConfirmation: boolean;
  setShowSubcityConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  existingLocation: boolean;
  setExistingLocation: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteLocation: (id: string) => void;
  onBlur?: () => void;
};

const WorkLocation = ({
  states,
  cities,
  workLocations,
  setWorkLocations,
  showSubcityConfirmation,
  setShowSubcityConfirmation,
  existingLocation,
  setExistingLocation,
  handleDeleteLocation,
  onBlur,
}: WorkLocationType) => {
  return (
    <>
      <h1 className="flex items-center pt-1 text-sm font-medium">
        Work Location(s)
        <span className="text-red-600 pl-1">*</span>
        <InformationCircleIcon
          data-tooltip-id="location-tooltip"
          className="w-5 h-5 ml-2"
        />
      </h1>
      <NearestLocation
        states={states}
        cities={cities}
        workLocations={workLocations}
        setWorkLocations={setWorkLocations}
        showSubcityConfirmation={showSubcityConfirmation}
        setShowSubcityConfirmation={setShowSubcityConfirmation}
        existingLocation={existingLocation}
        setExistingLocation={setExistingLocation}
        onBlur={onBlur}
      />
      <div>
        {workLocations.map((location) => (
          <div key={location.id} className="w-full pt-2 flex gap-2 font-medium">
            {location.name.trim().length !== 0 && (
              <div className="block col-span-3 w-full rounded-md border-0 px-2 py-1 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6">
                {location.name}
              </div>
            )}
            {location.nearestCity && (
              <div
                className={`col-span-2 block w-full rounded-md border-0 px-2 py-1 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 ${
                  location.name.trim().length === 0 && "col-span-5"
                }`}
              >
                {location.nearestCity}
              </div>
            )}
            <button
              type="button"
              className="col-span-1 w-fit px-1 text-white cursor-pointer bg-red-500 rounded-md"
              onClick={() => handleDeleteLocation(location.id)}
            >
              <XMarkIcon height={24} />
            </button>
          </div>
        ))}
      </div>
      <ReactTooltip
        key="location-tooltip"
        id="location-tooltip"
        content="This job is fully remote, but you're asked to provide up to two locations where you'll be working from."
      />
    </>
  );
};

export default WorkLocation;
