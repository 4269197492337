import { useEffect, useState } from "react";
import useIdToken from "../../../hooks/useIdToken";
import {
  GetCandidateStatus,
  GetRoleForCandidate,
  GetRoles,
} from "../../../WebCalls";
import { CandidateStatus, RoleStatus } from "../../../Models";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import Timeline from "./Timeline";
import { Role } from "../../../Models";
import RoleApplication from "../application/RoleApplication";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Spinner } from "../../shared/Spinner";

type tab = { name: string; icon: JSX.Element; pathname: string };

const tabs: tab[] = [
  {
    name: "Applied",
    icon: <ClipboardDocumentCheckIcon className="h-5" />,
    pathname: "/candidate",
  },
  {
    name: "Active",
    icon: <ClipboardDocumentListIcon className="h-5" />,
    pathname: "/candidate/active-roles",
  },
  {
    name: "Profile",
    icon: <UserIcon className="h-5" />,
    pathname: "/candidate/profile",
  },
];

export default function CandidateDashboard() {
  const [roles, setRoles] = useState<Role[]>([]);
  const [candidateStatus, setCandidateStatus] = useState<CandidateStatus[]>();
  const [roleLatestStatus, setRoleLatestStatus] = useState<{
    [roleId: string]: RoleStatus | undefined;
  }>({});

  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [currentRole, setCurrentRole] = useState<Role | null>(null);

  const [loading, setLoading] = useState(false);

  const idToken = useIdToken();
  const navigate = useNavigate();
  const location = useLocation();

  const isApplied = currentTab === tabs[0];

  useEffect(() => {
    const curTab = tabs.find((t) => t.pathname === location.pathname);
    setCurrentTab(curTab as tab);
  }, [location.pathname]);

  useEffect(() => {
    if (!idToken || currentTab.name === tabs[2].name) return;
    setLoading(true);
    GetRoleForCandidate(idToken.candidateId, idToken.token).then((res) => {
      if (currentTab === tabs[1])
        GetRoles().then((roles) => {
          const activeRoles = roles.filter((r) => r.isActive === true);
          const candidateActiveRoles = activeRoles.filter(
            (ar) => !(res ?? []).some((r) => r.id === ar.id),
          );
          setRoles(candidateActiveRoles);
          setLoading(false);
        });
      else if (currentTab === tabs[0]) {
        setRoles(res ?? []);
        GetCandidateStatus(idToken.candidateId, idToken.token).then((res) => {
          setCandidateStatus(res);
          setLoading(false);
        });
      }
    });
  }, [idToken, currentTab]);

  useEffect(() => {
    if (currentTab === tabs[1] || !candidateStatus) return;
    setRoleLatestStatus((prev) => {
      const updatedRoleLatestStatus = { ...prev };

      roles.forEach((r) => {
        const statuses = candidateStatus
          .filter((cs) => cs.roleId === r.id)
          .sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          );
        if (statuses.length !== 0)
          updatedRoleLatestStatus[r.id] = statuses[0].roleStatus;
      });
      return updatedRoleLatestStatus;
    });
  }, [candidateStatus, roles, currentTab]);

  const onTabChange = (tab: tab) => {
    setCurrentRole(null);
    navigate(tab.pathname);
  };

  const handleRoleClick = (role: Role) => {
    if (!isApplied) navigate("/role/" + role.id + "/overview");
    else setCurrentRole(role);
  };

  useEffect(() => {
    const storedIdToken = localStorage.getItem("idToken");
    if (!storedIdToken) {
      navigate("/candidate/login")
    }
  }, [])

  return (
    <div className="max-w-[85rem] mx-auto min-h-[75vh]">
      <div className="mx-12">
        <div className="border-b border-gray-200 flex justify-between">
          <nav className="flex w-full" aria-label="Tabs">
            <div className="lg:flex space-x-8 hidden ">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => {
                    onTabChange(tab);
                  }}
                  className={`${tab.name === currentTab.name
                    ? "border-entntorange text-entntorange"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    } whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium flex`}
                  aria-current={
                    tab.name === currentTab.name ? "page" : undefined
                  }
                >
                  <span>{tab.icon}</span>
                  <span>{tab.name}</span>
                </button>
              ))}
            </div>
          </nav>
        </div>
      </div>
      {currentTab === tabs[2] ? (
        <RoleApplication />
      ) : isApplied && currentRole && candidateStatus ? (
        <Timeline
          role={currentRole}
          candidateStatuses={candidateStatus}
          handleCurrentRole={(s) => {
            setCurrentRole(s);
          }}
        />
      ) : loading ? (
        <div className="flex justify-center items-center h-60">
          <Spinner />
        </div>
      ) : roles.length === 0 ? (
        <div className="mt-16 text-center">
          <span>
            {currentTab === tabs[0]
              ? "There are no roles currently applied."
              : "There are no active roles that you haven't applied for."}
          </span>
        </div>
      ) : (
        <ul className="mt-6 mb-20 mx-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {roles.map((job) => (
            <li
              key={job.title}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow cursor-pointer border border-gray-200"
              onClick={() => {
                handleRoleClick(job);
              }}
            >
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                      {job.title}
                    </h3>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-500">
                    {isApplied
                      ? "Status : " + (roleLatestStatus[job.id]?.name ?? "None")
                      : job.employmentType}
                  </p>
                </div>
                {isApplied ? (
                  <p className="text-sm text-gray-500">Timeline</p>
                ) : (
                  <p className="text-sm text-gray-500">Apply</p>
                )}
                <ChevronRightIcon
                  className="h-5 w-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
