import { useEffect, useRef } from "react";
import { addDays, addMonths, isSameMonth } from "date-fns";
import ReactDatePicker from "react-datepicker";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { TimelineCandidateEducation } from "../../Models";
import { addSeventeenYears } from "../../Helpers";

type Props = {
  handleEduExpChange: (
    entryType: "experience" | "education",
    field: "startDate" | "endDate",
    value: Date | null,
    id: string,
  ) => void;
  overlappingExp?: TimelineCandidateEducation[];
  isOverlapCorrect: boolean;
  dob: Date | null;
};

const OverlappingExperiences = ({
  handleEduExpChange,
  overlappingExp,
  isOverlapCorrect,
  dob,
}: Props) => {
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (datePickerRef.current) {
      const inputs = datePickerRef.current.querySelectorAll("input");
      inputs.forEach((input) => {
        input.setAttribute("tabindex", "-1");
      });
    }
  }, []);
  return (
    <div
      ref={datePickerRef}
      className={`text-xs pt-2 space-y-2 ${
        isOverlapCorrect && "opacity-[0.8] pointer-events-none select-none"
      }`}
    >
      {overlappingExp?.map((edu, index) => (
        <div key={edu.id} className="bg-gray-100 rounded-md p-2 space-y-2">
          <div>
            <h1
              className={`pb-1 ${
                edu.postGrad ? "text-[#e1ad01]" : "text-[#F88201]"
              }`}
            >
              {edu.university.name}
            </h1>
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
                <label htmlFor={`exp-start-date-${edu.id}`}>
                  <span className="w-10 sm:w-fit flex items-center h-9">
                    From
                  </span>
                </label>
                <ReactDatePicker
                  id={`exp-start-date-${edu.id}`}
                  className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                  selected={edu.startDate ? new Date(edu.startDate) : null}
                  onChange={(date) =>
                    handleEduExpChange(
                      "education",
                      "startDate",
                      date && addDays(date, 1),
                      edu.id,
                    )
                  }
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  placeholderText="eg. 01/2023"
                  closeOnScroll
                  shouldCloseOnSelect={true}
                  minDate={
                    overlappingExp[index - 1]?.endDate
                      ? addMonths(
                          new Date(overlappingExp[index - 1]?.endDate),
                          1,
                        )
                      : dob && addSeventeenYears(dob)
                  }
                  maxDate={new Date()}
                  showIcon
                  icon={
                    <CalendarDaysIcon className="text-gray-400 absolute bottom-1" />
                  }
                  calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                  onFocus={(e) => {
                    if (/iPhone|iPad/i.test(navigator.userAgent)) {
                      e.target.readOnly = true;
                    }
                  }}
                  disabled={isOverlapCorrect}
                />
              </div>
              <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
                <label htmlFor={`exp-end-date-${edu.id}`}>
                  <span className="w-10 sm:w-fit flex items-center h-9">
                    To
                  </span>
                </label>
                <ReactDatePicker
                  id={`exp-end-date-${edu.id}`}
                  className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                  selected={edu.endDate ? new Date(edu.endDate) : null}
                  onChange={(date) =>
                    handleEduExpChange(
                      "education",
                      "endDate",
                      date && addDays(date, 1),
                      edu.id,
                    )
                  }
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  minDate={new Date(edu.startDate)}
                  filterDate={(date) => {
                    if (edu.startDate) {
                      return !isSameMonth(date, new Date(edu.startDate));
                    }
                    return true;
                  }}
                  placeholderText="eg. 01/2024"
                  closeOnScroll
                  shouldCloseOnSelect={true}
                  disabled={!edu.startDate || isOverlapCorrect}
                  maxDate={new Date()}
                  showIcon
                  icon={
                    <CalendarDaysIcon className="text-gray-400 absolute bottom-1" />
                  }
                  calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                  onFocus={(e) => {
                    if (/iPhone|iPad/i.test(navigator.userAgent)) {
                      e.target.readOnly = true;
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {edu.overlappingExperience.map((exp, idx) => (
            <div key={`${exp.id}-${idx}`}>
              {exp.company && (
                <>
                  <h1 className="pb-1 text-[#0D42EC]">
                    {exp.company && exp.company.name}
                  </h1>
                  <div className="flex flex-col sm:flex-row gap-3">
                    <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
                      <label htmlFor={`exp-start-date-${exp.id}`}>
                        <span className="w-10 sm:w-fit flex items-center h-9">
                          From
                        </span>
                      </label>
                      <ReactDatePicker
                        id={`exp-start-date-${exp.id}`}
                        className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                        selected={
                          exp.startDate ? new Date(exp.startDate) : null
                        }
                        onChange={(date) =>
                          handleEduExpChange(
                            "experience",
                            "startDate",
                            date && addDays(date, 1),
                            exp.id,
                          )
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        placeholderText="eg. 01/2023"
                        closeOnScroll
                        shouldCloseOnSelect={true}
                        maxDate={new Date()}
                        showIcon
                        icon={
                          <CalendarDaysIcon className="text-gray-400 absolute bottom-1" />
                        }
                        calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                        onFocus={(e) => {
                          if (/iPhone|iPad/i.test(navigator.userAgent)) {
                            e.target.readOnly = true;
                          }
                        }}
                        disabled={isOverlapCorrect}
                      />
                    </div>
                    <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
                      <label htmlFor={`exp-end-date-${exp.id}`}>
                        <span className="w-10 sm:w-fit flex items-center h-9">
                          To
                        </span>
                      </label>
                      <ReactDatePicker
                        id={`exp-end-date-${exp.id}`}
                        className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                        selected={exp.endDate ? new Date(exp.endDate) : null}
                        onChange={(date) =>
                          handleEduExpChange(
                            "experience",
                            "endDate",
                            date && addDays(date, 1),
                            exp.id,
                          )
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        minDate={addMonths(new Date(exp.startDate), 1)}
                        filterDate={(date) => {
                          if (exp.startDate) {
                            return !isSameMonth(date, new Date(exp.startDate));
                          }
                          return true;
                        }}
                        placeholderText="eg. 01/2024"
                        closeOnScroll
                        shouldCloseOnSelect={true}
                        disabled={!exp.startDate || isOverlapCorrect}
                        maxDate={new Date()}
                        showIcon
                        icon={
                          <CalendarDaysIcon className="text-gray-400 absolute bottom-1" />
                        }
                        calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                        onFocus={(e) => {
                          if (/iPhone|iPad/i.test(navigator.userAgent)) {
                            e.target.readOnly = true;
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default OverlappingExperiences;
