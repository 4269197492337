import { useEffect, useRef, useState } from "react";
import { candidateLogin, requestOtp } from "../../../WebCalls";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../../shared/Spinner";

const validateEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(email);
};

export default function Login({ isLoggedIn }: { isLoggedIn: Function }) {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [requestingOtp, setRequestingOtp] = useState(false);
  const [login, setLogin] = useState(false);
  const [error, setError] = useState<string>();
  const [otpTimer, setOtpTimer] = useState<number>(60);
  const [emailIsValid, setEmailIsValid] = useState();

  const navigate = useNavigate();

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (login && otpTimer > 0) {
      timer = setInterval(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [login, otpTimer]);

  const handleChange = (element: HTMLInputElement, index: number) => {
    const value = element.value;
    if (isNaN(Number(value))) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
    setError("");
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleLogin = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    try {
      if (!login) {
        setError("");
        if (!validateEmail(email)) {
          setError("Invalid Email !");
          return;
        }
        setRequestingOtp(true);
        await requestOtp(email);
        setLogin(true);
        setRequestingOtp(false);
      } else {
        const res = await candidateLogin({ email, otp: otp.join("") });
        localStorage.setItem("idToken", JSON.stringify(res));
        isLoggedIn();
        navigate("/candidate");
        setLogin(false);
      }
    } catch (er) {
      if (er instanceof Error) {
        setError(er.message);
      } else {
        setError("An unknown error occurred.");
      }
      setRequestingOtp(false);
    }
  };

  const handleOtpResend = async () => {
    await requestOtp(email);
    setLogin(true);
    setOtpTimer(120);
    setError("")
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Log in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => {
                    setError("");
                    setEmail(e.target.value);
                  }}
                  value={email}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {login && (
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Enter OTP
                </label>
                <div className="flex space-x-2 justify-center mt-2">
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="w-12 h-12 text-center text-2xl border border-gray-300 rounded"
                        type="text"
                        name="otp"
                        maxLength={1}
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    );
                  })}
                </div>
                {otpTimer === 0 ? (
                  <div
                    onClick={handleOtpResend}
                    className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 text-xs text-right mt-4 cursor-pointer pr-4"
                  >
                    Resend OTP
                  </div>
                ) : (
                  <div className=" leading-6  text-xs text-center mt-4">
                    You can resend OTP after {otpTimer} seconds
                  </div>
                )}
              </div>
            )}
            <div>
              <button
                onClick={handleLogin}
                type="button"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:hover:bg-indigo-600"
                disabled={
                  (login && otp.join("").length < 6) ||
                  email.trim().length === 0
                }
              >
                {!login ? (
                  <span>
                    {requestingOtp ? <Spinner h="h-6" w="w-6" /> : "Request"}
                  </span>
                ) : (
                  "Login"
                )}
              </button>
            </div>
            <div className="flex items-center justify-center text-sm min-h-4 font-medium">
              {error && <span className="text-red-500">{error}</span>}
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not applied yet for any role?{" "}
            <Link
              to="/"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Go to careers home
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
