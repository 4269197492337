import {
  BriefcaseIcon,
  CurrencyDollarIcon,
  InformationCircleIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import { Role } from "../../../Models";

export default function RoleInfo({ role }: { role: Role }) {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  let timeoutIdRef: NodeJS.Timeout;
  return (
    <div className="relative">
      <InformationCircleIcon
        className="hidden sm:block h-5 text-gray-500 hover:text-gray-600 cursor-pointer"
        onMouseEnter={(e) => {
          timeoutIdRef = setTimeout(() => {
            setInfoModalOpen(true);
          }, 500);
        }}
        onMouseLeave={(e) => {
          if (timeoutIdRef) {
            window.clearTimeout(timeoutIdRef);
          }
          setInfoModalOpen(false);
        }}
      ></InformationCircleIcon>
      {infoModalOpen && (
        <div className="absolute transform  flex flex-col sm:mt-0 bg-white z-[999] border border-gray-200 w-[20rem] rounded-md shadow-md px-4 py-2 space-y-2">
          <div className="whitespace-normal flex items-center text-xs text-gray-70">
            <BriefcaseIcon
              className="mr-1.5 h-4 w-4 flex-shrink-0 text-entntblue"
              aria-hidden="true"
            />
            {role.employmentType}
          </div>
          <div className="flex items-center text-xs text-gray-700">
            <MapPinIcon
              className="mr-1.5 h-4 w-4 flex-shrink-0 text-entntblue"
              aria-hidden="true"
            />
            {role.location}
          </div>
          <div className="whitespace-normal flex items-center text-xs text-gray-700">
            <CurrencyDollarIcon
              className="mr-1.5 h-4 w-4 flex-shrink-0 text-entntblue"
              aria-hidden="true"
            />
            {role.salary}
          </div>
        </div>
      )}
    </div>
  );
}
