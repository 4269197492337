import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/20/solid";

type buttonContentsType = {
  buttonId: number;
  buttonLabel: string;
  buttonAction?: (
    e: React.SyntheticEvent<HTMLButtonElement, Event>,
  ) => void | Promise<void>;
  isOutline?: boolean;
  buttonNavigation?: string;
};
type descriptionListType = {
  descId: number;
  description: string;
};

type props = {
  modalId?: string;
  type: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description?: string|JSX.Element;
  descriptionList?: descriptionListType[];
  buttonContents?: buttonContentsType[];
};

export default function ConfirmModal(props: props) {
  const cancelButtonRef = useRef(null);
  const danger = props.type === "danger";

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        id={props.modalId}
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500  bg-opacity-50 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex items-start">
                    {!props.descriptionList && (
                      <div
                        className={`mx-auto sm:-mt-2 flex flex-shrink-0 items-center justify-center rounded-full ${
                          danger ? "bg-red-100" : "bg-blue-100"
                        } sm:mx-0 h-10 w-10`}
                      >
                        {props.title.includes("successful") ? (
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <ExclamationTriangleIcon
                            className={`h-6 w-6 ${
                              danger ? "text-red-600" : "text-blue-600"
                            }`}
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    )}
                    <div className="mt-2 text-left ml-4 sm:mt-0">
                      <Dialog.Title
                        as="h3"
                        className={`text-base font-semibold leading-6 text-gray-900 ${
                          props.descriptionList && "text-center"
                        }`}
                      >
                        {props.title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="text-sm font-medium text-gray-500">
                          {props.description
                            ? props.description
                            : props.descriptionList?.map((desc) => (
                                <li
                                  key={desc.descId}
                                  className="list-decimal pt-4 "
                                >
                                  {desc.description}
                                </li>
                              ))}
                        </div>
                        {props.title.includes("successful") && (
                          <p className="text-sm font-medium text-gray-500 mt-2">
                            Redirecting in 5 seconds.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6">
                  {props.buttonContents?.map((btn) =>
                    btn.buttonNavigation ? (
                      <Link
                        key={btn.buttonId}
                        to={btn.buttonNavigation ? btn.buttonNavigation : "/"}
                        className="w-full"
                      >
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-entntblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntblue"
                        >
                          {btn.buttonLabel}
                        </button>
                      </Link>
                    ) : (
                      <button
                        key={btn.buttonId}
                        type="button"
                        className={`inline-flex w-full justify-center rounded-md
                          px-3 py-2 text-sm font-semibold  
                         shadow-sm  focus-visible:outline 
                         focus-visible:outline-2 focus-visible:outline-offset-2 
                         focus-visible:outline-entntblue mx-4
                          ${
                            btn.isOutline
                              ? "bg-white text-gray-900 hover:bg-gray-50 ring-1 ring-inset ring-gray-300"
                              : "bg-entntblue text-white hover:bg-entntorange"
                          }`}
                        onClick={
                          btn.buttonAction
                            ? btn.buttonAction
                            : (e) => {
                                e.preventDefault();
                                props.setOpen(false);
                              }
                        }
                      >
                        {btn.buttonLabel}
                      </button>
                    ),
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
